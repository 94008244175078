import { put, takeLatest, call } from "redux-saga/effects";
import {

  GET_ALL_LISTS_REQUESTED,
  GET_ALL_LISTS_SUCCESS,
  GET_ALL_LISTS_FAILED,

  GET_BOT_LIST_REQUESTED,
  GET_BOT_LIST_SUCCESS,
  GET_BOT_LIST_FAILED,
  GET_BRANDS_LIST_REQUESTED,
  GET_BRANDS_LIST_SUCCESS,
  GET_BRANDS_LIST_FAILED,
  GET_AGGREGATOR_LIST,
  GET_AGGREGATOR_LIST_SUCCESS,
  GET_AGGREGATOR_LIST_FAILED,
  GET_SUMMARY_CARDS,
  GET_SUMMARY_CARDS_FAILED,
  GET_SUMMARY_CARDS_SUCCESS,
  GRAPH_DATA_REQUESTED,
  GRAPH_DATA_FAILED,
  GRAPH_DATA_SUCCESS,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import {
  makeSecuredPostRequest,
  makeSecuredGetRequest,
} from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getAllListSaga.js";

function* workerGetAllLists({data={}}) {
  try {
    let endpoint = API_ENDPOINT.GET_ALL_FILTER_DATA_LISTS;
    console.log("workerGetAllLists ---> ", endpoint)

    if(data.whoIsThis==="super-admin" || data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_FILTER_DATA_LISTS;

    // if(data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_AGGREGATORS_FOR_VI;

    const response = yield call(
      makeSecuredPostRequest,
      endpoint,
      data.payload
    );

    // let response = {
    //   data: {
    //     "status_code": 10,
    //     "status_message": "Success",
    //     "message": null,
    //     "data": {
    //         "brand": [
    //             {
    //                 "label": "VI",
    //                 "value": "VI"
    //             },
    //             {
    //                 "label": "Vodafone Idea",
    //                 "value": "Vodafone Idea"
    //             }
    //         ],
    //         "aggregetors": [
    //             {
    //                 "label": "187",
    //                 "value": "Vodafone Idea Limited"
    //             },
    //             {
    //                 "label": "215",
    //                 "value": "gupshup"
    //             }
    //         ],
    //         "bots": [
    //             {
    //                 "label": "Vi",
    //                 "value": "Vi"
    //             },
    //             {
    //                 "label": "VI RBM",
    //                 "value": "VI RBM"
    //             }
    //         ]
    //     }
    //   }
    // }
    
    yield put(createActionOf(GET_ALL_LISTS_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get all lists for admin failed: " + error.message);
    yield put(createActionOf(GET_ALL_LISTS_FAILED, 2, error));
  }
}

function* workerGetAggregators({data={}}) {
  try {
    
  
    let endpoint = API_ENDPOINT.GET_ALL_AGGREGATORS_FOR_CARRIERS;
    if(data.whoIsThis==="super-admin") endpoint = API_ENDPOINT.GET_ALL_AGGREGATORS_FOR_DOTGO;
    if(data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_AGGREGATORS_FOR_VI;
    const response = yield call(
      makeSecuredGetRequest,
      endpoint
    );
    yield put(createActionOf(GET_AGGREGATOR_LIST_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get aggregators failed: " + error.message);
    yield put(createActionOf(GET_AGGREGATOR_LIST_FAILED, 2, error));
  }
}
function* workerGetBrands({data={}}) {
  try {
    console.log("🚀 ~ file: getAllListsSaga.js ~ line 46 ~ function*workerGetBrands ~ data", data)
    let endpoint = API_ENDPOINT.GET_ALL_BRANDS_FOR_CARRIERS;
    if(data.whoIsThis==="super-admin") endpoint = API_ENDPOINT.GET_ALL_BRANDS_FOR_DOTGO;
    if(data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_BRANDS_FOR_VI;
    const response = yield call(
      makeSecuredGetRequest,
      endpoint,
    );
    yield put(createActionOf(GET_BRANDS_LIST_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get brands failed: " + error.message);
    yield put(createActionOf(GET_BRANDS_LIST_FAILED, 2, error));
  }
}
function* workerGetBots({data}) {
  console.log("[pp]", data);
  
  let endpoint = API_ENDPOINT.GET_ALL_BOTS_FOR_CARRIERS;
  if(data.whoIsThis==="super-admin") endpoint = API_ENDPOINT.GET_ALL_BOTS_FOR_DOTGO;
  if(data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_BOTS_FOR_VI;
  try {
    const response = yield call(
      makeSecuredGetRequest,
      endpoint
    );
    yield put(createActionOf(GET_BOT_LIST_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get bots failed: " + error.message);
    yield put(createActionOf(GET_BOT_LIST_FAILED, 2, error));
  }
}
function* workerGetSummaryCards(payLoad = {}) {
  try {
    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_SUMMARY_CARDS,
      payLoad.data
    );
    yield put(createActionOf(GET_SUMMARY_CARDS_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get summary cards failed: " + error.message);
    yield put(createActionOf(GET_SUMMARY_CARDS_FAILED, 2, error));
  }
}

export const watchGetAggregators = takeLatest(
  GET_AGGREGATOR_LIST,
  workerGetAggregators
);
export const watchGetBrands = takeLatest(
  GET_BRANDS_LIST_REQUESTED,
  workerGetBrands
);

export const watchGetBots = takeLatest(GET_BOT_LIST_REQUESTED, workerGetBots);
export const watchGetSummaryCards = takeLatest(
  GET_SUMMARY_CARDS,
  workerGetSummaryCards
);

export const watchGetAllLists = takeLatest(
  GET_ALL_LISTS_REQUESTED,
  workerGetAllLists
)