import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  UPDATE_PARTNERS_PROFILE_SUCCESS,
  UPDATE_PARTNERS_PROFILE_REQUESTED,
  UPDATE_PARTNERS_PROFILE_FAILED,
  RESET_STATE,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};
export default function updatePartnersProfileReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_PARTNERS_PROFILE_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case UPDATE_PARTNERS_PROFILE_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "came to the updatePartnersProfile success   " + JSON.stringify(action)
      );
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.data = action.data.data;
      userRes.error = false;
      return userRes;

    case UPDATE_PARTNERS_PROFILE_FAILED:
      return Object.assign({}, state, action.error);

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
