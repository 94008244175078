import { LOADING } from "../../../constants/constant";
import {
  GET_ALL_TRAFFIC_LISTS_REQUESTED,
  GET_ALL_TRAFFIC_LISTS_SUCCESS,
  GET_ALL_TRAFFIC_LISTS_FAILED,
  GET_ALL_TRAFFIC_LISTS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export function getFilterDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TRAFFIC_LISTS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_ALL_TRAFFIC_LISTS_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data?.data?.status_message;
      userRes.statusCode = action.data?.data?.status_code;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.brandList = action.data?.data?.brand;
      userRes.aggregatorList = action.data?.data.aggregetors;
      userRes.botList = action.data?.data?.bots;
      userRes.templateList = action.data?.data.template;
      userRes.agentTypeList = action.data?.data.agentType;
      userRes.industryTypeList = action.data?.data.industryType;

      console.log("Check lists : ", action.data?.data)

      return userRes;

    case GET_ALL_TRAFFIC_LISTS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_ALL_TRAFFIC_LISTS_RESET:
      return initialState;

    default:
      return state;
  }
}

