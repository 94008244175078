import { LOADING } from '../../../constants/constant';
import {
  DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_REQUESTED,
  DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_SUCCESS,
  DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_FAILED,
  DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_RESET,
} from '../../actions/ActionType';

const initialState = {
  status: 'INIT',
  isLoading: false,
};

export default function getDetailedDashboardReportReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_REQUESTED:
      console.log(
        'step 3 : getDetailedDashboardReportReducer > DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_REQUESTED'
      );
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.isLoading = false;
      return userRes;

    case DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_FAILED:
      return Object.assign({}, state, action.error);

    case DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_RESET:
      let resetObj = Object.assign({}, state);
      resetObj.status = 'constant';
      resetObj.statusCode = 0;
      return resetObj;

    default:
      return state;
  }
}
