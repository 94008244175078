import { all } from 'redux-saga/effects';
import { watchVerifyEmail } from './verifyEmailSaga';
import { watchSetPassword } from './setPasswordSaga';
import { watchSetupuserDetails } from './setupUserDetailsSaga';
import { watchPartnerDetails } from './partnerDetailsSaga';
import { watchBotList } from './botListSaga';
import { watchCategoryList } from './categoriesSaga';
import { watchCarrierList } from './carriersSaga';
import { watchCountries } from './countriesSaga';
import { watchLoadBotReview } from './loadBotReviewsSaga';
import { watchSubmitBotReview } from './submitBotReviewSaga';
import { watchGenerateOtp } from './generateOtpSaga';
import { watchValidateOtp } from './validateOtpSaga';

import { watchSubmitWhatsAppBot } from './submitWhatsAppSaga';
import { watchUpdateWhatsAppBot } from './updateWhatsApp';
import { watchGetBrandsSaga } from './getBrandsSaga';
import { watchConfimRCSSubmission } from './confirmRCSSubmissionSaga';
import { watchConfimWhatsAppSubmission } from './confirmWhatsAppSubmissionSaga';
import { watchGetBotDetailsSaga } from './getBotDetailsSaga';
import { watchGetBrandDetailsSagaPreview } from './Onboarding/getSingleBrandSagaPreview';

import { watchStepTwo } from './LaunchRequest/stepTwoSaga';
import { watchGetLaunchCarriers } from './LaunchRequest/getLaunchCarrierSaga';
import { watchGetInitialDetails } from './LaunchRequest/getInitialDetailsSaga';
import { watchSubmitCarrier } from './LaunchRequest/submitCarrierSaga';
import { watchGetPartnersBrandList } from './getPartnersBrandListSaga';
import { watchGetIndustryTypes } from './getIndustryTypesSaga';
import { watchSubmitBrand } from './submitBrandSaga';
import { watchGetBrandDetailsSaga } from './getSingleBrandSaga';
import { watchGetVerifiedCarriers } from './Aggregator/getVerifiedCarrierSaga';
import { watchUpdateBrand } from './updateBrandSaga';
import { watchSubmitVerifiedCarriers } from './Aggregator/submitVerifiedCarriersSaga';
import { watchSubmitBotSummary } from './Aggregator/submitBotSummarySaga';
import { watchGetPartnersCarrierss } from './Aggregator/getPartnersCarriersSaga';
import { watchGetBotWhatsAppDetailsSaga } from './getBotWhatsAppSaga';
import { watchUpdatePartnersProfile } from './updatePartnersProfileSaga';
import { watchgetLaunchSummary } from './Aggregator/getLaunchSummarySaga';
import { watchChangePassword } from './changePasswordSaga';
import { watchGetPricing } from './getPriceSaga';
import { watchGetAllUsersAdminPortal } from './AdminPortalSagas/getAllUsersAdminPortalSaga';

import { watchDashboardAnalytics } from './billingSaga/dashboardAnalyticsSaga';
import { watchFilterDashboardSummaryForGraph } from './billingSaga/GraphSaga';
import { watchReInviteUserAdminPortal } from './AdminPortalSagas/reInviteUserAdminPortalSaga';
import { watchAdminGetSubmittedBrands } from './AdminPortalSagas/My Brands/AdminGetSubmittedBrandsSaga';
import { watchAdminGetRejectedBrands } from './AdminPortalSagas/My Brands/AdminGetRejectedBrandsSaga';
import { watchAdminGetApprovedBrands } from './AdminPortalSagas/My Brands/AdminGetApprovedBrandsSaga';

import { watchGetRichPromotionsAndNotificationsCampaignSummary } from './getRichPromotionsAndNotificationsCampaignSummarySaga';
import { watchGetRichPromotionsAndNotificationsCampaignDetailedReport } from './getRichPromotionsAndNotificationsCampaignDetailedReportSaga';
import {
  watchGetAggregators,
  watchGetBrands,
  watchGetBots,
  watchGetSummaryCards,
} from './billingSaga/getAllListsSaga';

import { watchInviteUser } from './billingSaga/InviteUserSaga';
import { watchReInviteUser } from './billingSaga/ReInviteUserSaga';
import { watchGetAllUsers } from './billingSaga/getAllUsersSaga';
import { watchDeleteUser } from './billingSaga/deleteUserSaga';
import { watchSetBillingUserPassword } from './billingSaga/setBillingUserPasswordSaga';
import { watchUpdateCarrierProfile } from './billingSaga/updateCarrierProfileSaga';
import { watchCarrierGetSubmittedBrands } from './billingSaga/CarrierGetSubmittedBrandsSaga';
import { watchCarrierGetRejectedBrands } from './billingSaga/CarrierGetRejectedBrandsSaga';
import { watchCarrierGetApprovedBrands } from './billingSaga/CarrierGetApprovedBrandsSaga';
import { watchGetCarrierSubmittedBots } from './billingSaga/get_submitted_bots_for_carrier';
import { watchGetCarrierApprovedBots } from './billingSaga/get_approved_bots_saga';
import { watchGetListOfAggregator } from './billingSaga/getListOfAggregatorSaga';
import { watchGetCarrierRejectedBots } from './billingSaga/getRejectedBotsForCarrierSaga';
import { watchApproveOrRejectBots } from './billingSaga/approve_or_reject_botSaga';
import { watchApproveOrRejectBrands } from './billingSaga/ApproveOrRejectBrandsSaga';
import { watchAdminApproveOrRejectBrands } from './AdminPortalSagas/My Brands/AdminApproveOrRejectBrandsSaga';
import { watchGetSubmittedAggregatorsForCarrier } from './billingSaga/getSubmittedAggregatorsForCarrierSaga';
import { watchGetApprovedAggregatorsForCarrier } from './billingSaga/getApprovedAggregatorsForCarrierSaga';
import { watchGetRejectedAggregatorsForCarrier } from './billingSaga/getRejectedAggregatorsForCarrierSaga';
import { watchGetAggregatorInfo } from './billingSaga/getAggregatorInfoSaga';
import { watchApproveOrRejectAggregators } from './billingSaga/approveOrRejectCarrierAggregatorSaga';
import { watchInviteUserAdminPortal } from './AdminPortalSagas/InviteUserAdminPortalSaga';
import { watchGetAdminSubmittedBots } from '../sagas/AdminPortalSagas/get_submitted_bots_for_adminSaga';
import { watchGetAdminRejectedBots } from './AdminPortalSagas/get_rejected_bots_for_adminSaga';
import { watchGetAdminForwardedBots } from './AdminPortalSagas/get_forwarded_bots_for_adminSaga';
import { watchGetAdminApprovedBots } from './AdminPortalSagas/get_approved_bots_for_adminSaga';
import { watchApproveOrRejectBotsAdmin } from './AdminPortalSagas/admin_approve_or_reject_bot_Saga';
import { watchApproveOrRejectDotgoTemplateAdmin } from './AdminPortalSagas/admin_approve_or_reject_dotgo_templ_saga';
import { watchBotHistory } from './AdminPortalSagas/bot_history_saga';
import { watchForwardBots } from './AdminPortalSagas/forward_bots_for_carrier_saga';
import { watchDeleteUserAdminPortal } from './AdminPortalSagas/deleteUserAdminPortalSaga';
import { watchUpdateCarrierProfileAdminPortal } from './AdminPortalSagas/updateCarrierProfileAdminPortalSaga';
import { watchGetAdminSubmittedAggregators } from './AdminPortalSagas/get_admin_submitted_aggregators_saga';
import { watchGetAdminApprovedAggregators } from './AdminPortalSagas/get_admin_approved_aggregators_saga';
import { watchGetAdminRejectedAggregator } from './AdminPortalSagas/get_admin_rejected_aggregators_saga';
import { watchGetAdminForwardedAggregator } from './AdminPortalSagas/get_pending_aggregators_saga';
import { watchApproveOrRejectAggregatorsAdmin } from './AdminPortalSagas/admin_approve_or_reject_agg_saga';
import { watchApproveOrRejectDotgoAggregatorsAdmin } from './AdminPortalSagas/admin_approve_or_reject_dotgo_agg_saga';
import { watchForwardAggregator } from './AdminPortalSagas/forward_aggregator_saga';
import { watchAggHistory } from './AdminPortalSagas/get_aggregator_historySaga';
import { watchGetSingleTemplateDetailsSaga } from './AdminPortalSagas/getSingleTemplate';

import { watchGetAdminSubmittedDotgoAggregators } from './AdminPortalSagas/get_admin_submitted_dotgo_aggregators_saga';
import { watchGetAdminTemplates } from './AdminPortalSagas/get_admin_templates_saga';

import { watchPendingApprovalBrands } from './AdminPortalSagas/My Brands/PendingApprovalSaga';
import { watchForwardBrand } from './AdminPortalSagas/My Brands/ForwardBrandSaga';
import { watchBrandHistory } from './AdminPortalSagas/My Brands/BrandHistorySaga';
import { watchGetAdminCampaigns } from './AdminPortalSagas/get_admin_campaign_saga';
import { watchGetPricePageDetails } from './Aggregator/getPricePageDetailsSaga';

// import { watchGetCountryCarriersAll } from "./Onboarding/getAllCarrierAndCountrySaga";
// import { watchSubmitPreselectedCarrierList } from "./Onboarding/submitPreselectedCarriersSaga";
// import { watchCreateRCSBot } from "./createRCSBot.saga";
import { watchAddRichTemplate } from './Onboarding/addTemplateSaga';
import { watchGetBotCreationPreviewSaga } from './Onboarding/botCreationPreviewSaga';

import { watchGetCountryCarriersAll } from './Onboarding/getAllCarrierAndCountrySaga';
import { watchSubmitPreselectedCarrierList } from './Onboarding/submitPreselectedCarriersSaga';
import { watchCreateRCSBot } from './createRCSBot.saga';
import { watchFetchGeoLocation } from './Onboarding/GeoLocationSaga';
import { watchSubmitSelectedCarriers } from './Onboarding/submitSelectedCarriersSaga';
import { watchGetBotScreens } from './Onboarding/botScreenSaga';
import { watchUpdateListing } from './Onboarding/updateListingSaga';
import {
  watchGetApprovedBotsForListing,
  watchGetRejectedBotsForListing,
  watchGetSubmittedBotsForListing,
  watchGetBotListingHistory,
} from './AdminPortalSagas/AdminListingSagas/adminListingSagas';
import {
  watchGetSubmittedBotsForVerification,
  watchApproveRejectBotForVerification,
} from './AdminPortalSagas/AdminVerificationSagas/AdminVerificationSagas';
import { watchDeleteBots } from './Onboarding/deleteBotSaga';
import { watchGetVerifiedBotDetailsSaga } from './Onboarding/GetVerifiedBotDetailsSaga';

import { watchDeleteDocs } from './Onboarding/deleteDocumentSaga';
import { watchDeleteTemplate } from './Onboarding/deleteTemplateSaga';
import { watchGetTestDevicesSaga } from './Onboarding/getTestDevicesSaga';
import { watchAddTestDevicesSaga } from './Onboarding/addTestDeviceSaga';
import { watchDeleteTestDevicesSaga } from './Onboarding/deleteTestDeviceSaga';
import { watchSendMessageSaga } from './Onboarding/sendMessageSaga';
import { watchGetTemplateDetailsSaga } from './Onboarding/viewTemplateDetailsSaga';
import { watchGetTemplateHistorySaga } from './Onboarding/getTemplateHistorySaga';
import { watchUpdateRCSBotOnboardingSaga } from './Onboarding/updateRCSBotOnboardingSaga';

import {
  watchApproveRejectBotForCreation,
  watchInsertAgent,
  watchKRCSCreation,
  watchGetAgentInfo,
  watchMaaPAgent,
} from './AdminPortalSagas/AdminCreationSagas/AdminCreationSagas';

import {
  watchGetAllCampaigns,
  watchGetBotAndTEmplates,
} from './campaign/campaignSagas';
import { watchTestTemplate } from './testTemplate/testTemplateSaga';
import { watchGetTestSummarySaga } from './testTemplate/testTemplateSummary';
import { watchRetestTemplateSaga } from './testTemplate/reTestTemplateSaga';

import { watchAgentMsgTypeList } from './agentMsgTypeListSaga';
import { watchGetTemplateJSONSaga } from './Onboarding/getTemplateJSON.saga';

import { watchGetAllLists } from './billingSaga/getAllListsSaga';
import { watchAnalyticsBillingSummary } from './billingSaga/analyticsBillingSummarySaga';
import { watchAnalyticsBillingTabularView } from './billingSaga/analyticsBillingTabularViewSaga';
import { watchGetDownloadUploadedContacts } from './getDownloadUploadedContactsSaga';

import { watchGetBillingDetailedReport } from './getBillingDetailedReportSaga';
import { watchGetTrafficTableData } from './getTrafficTableDataSaga';
import { watchGetFilterData } from './trafficSaga/getFilterDataSaga';
import { watchGetDetailedRCSTrafficReportSaga } from './trafficSaga/getDetailedRCSTrafficReportSaga';
import { watchGetDetailedExcelReportSaga } from './getDetailedExcelReportSaga';
import { watchGetFailedCounts } from './getFailedCountsSaga';
import { watchGetP2AMessageCounts } from './getP2AMessageCountsSaga';
import { watchGetP2AResponseCounts } from './getP2AResponseCountsSaga';
import { watchGetDetailedDashboardReportSaga } from './DetailedDasboardSagas/getDetailedDashboardReportSaga';
import {watchGetDeliveryTableData} from "../sagas/getDeliveryTableDataSaga";
import { watchGetDeliveredCounts } from './getDeliveredCountsSaga';

export default function* rootSaga() {
  yield all([
    watchGetAllLists,
    watchAnalyticsBillingSummary,
    watchAnalyticsBillingTabularView,
    // watchVerifyEmail,
    watchInviteUser,
    watchReInviteUser,
    watchDeleteUser,
    watchGetAllUsers,
    watchSetPassword,
    watchSetupuserDetails,
    watchSetBillingUserPassword,
    watchPartnerDetails,
    watchBotList,
    watchCategoryList,
    watchCarrierList,
    watchCountries,
    watchSubmitWhatsAppBot,
    watchUpdateWhatsAppBot,
    watchGetSingleTemplateDetailsSaga,
    watchGetBrandsSaga,
    watchConfimRCSSubmission,
    watchConfimWhatsAppSubmission,
    watchGetBotDetailsSaga,
    watchStepTwo,
    watchGetLaunchCarriers,
    watchGetAdminCampaigns,
    watchGetInitialDetails,
    watchSubmitCarrier,
    watchGetPartnersBrandList,
    watchGetIndustryTypes,
    watchSubmitBrand,
    watchGetBrandDetailsSaga,
    watchGetVerifiedCarriers,
    watchUpdateBrand,
    watchSubmitVerifiedCarriers,
    watchSubmitBotSummary,
    watchGetPartnersCarrierss,
    watchGetBotWhatsAppDetailsSaga,
    watchUpdatePartnersProfile,
    watchGetRichPromotionsAndNotificationsCampaignSummary,
    watchGetRichPromotionsAndNotificationsCampaignDetailedReport,
    watchgetLaunchSummary,
    watchChangePassword,
    watchGetPricing,
    watchGetAggregators,
    watchGetBrands,
    watchGetBots,
    watchDashboardAnalytics,
    watchGetSummaryCards,
    watchUpdateCarrierProfile,
    watchCarrierGetSubmittedBrands,
    watchCarrierGetRejectedBrands,
    watchCarrierGetApprovedBrands,
    watchGetCarrierSubmittedBots,
    watchGetCarrierApprovedBots,
    watchGetListOfAggregator,
    watchGetCarrierRejectedBots,
    watchApproveOrRejectBots,
    watchApproveOrRejectBrands,
    watchGetSubmittedAggregatorsForCarrier,
    watchGetApprovedAggregatorsForCarrier,
    watchGetRejectedAggregatorsForCarrier,
    watchGetAggregatorInfo,
    watchApproveOrRejectAggregators,
    watchFilterDashboardSummaryForGraph,
    watchGetAllUsersAdminPortal,
    watchGetAdminSubmittedBots,
    watchGetAdminRejectedBots,
    watchGetAdminApprovedBots,
    watchGetAdminForwardedBots,
    watchApproveOrRejectBotsAdmin,
    watchBotHistory,
    watchForwardBots,
    watchInviteUserAdminPortal,
    watchReInviteUserAdminPortal,
    watchDeleteUserAdminPortal,
    watchUpdateCarrierProfileAdminPortal,
    watchAdminApproveOrRejectBrands,
    watchAdminGetSubmittedBrands,
    watchAdminGetRejectedBrands,
    watchAdminGetApprovedBrands,
    watchPendingApprovalBrands,
    watchForwardBrand,
    watchBrandHistory,
    watchLoadBotReview,
    watchSubmitBotReview,
    watchGetAdminSubmittedAggregators,
    watchGetAdminSubmittedDotgoAggregators,
    watchGetAdminTemplates,
    watchGetAdminApprovedAggregators,
    watchGetAdminRejectedAggregator,
    watchGetAdminForwardedAggregator,
    watchApproveOrRejectDotgoAggregatorsAdmin,
    watchApproveOrRejectAggregatorsAdmin,
    watchForwardAggregator,
    watchAggHistory,
    watchGetPricePageDetails,
    watchGetCountryCarriersAll,
    watchSubmitPreselectedCarrierList,
    watchCreateRCSBot,
    watchApproveOrRejectDotgoTemplateAdmin,
    watchAddRichTemplate,
    // ]);
    watchFetchGeoLocation,
    watchSubmitSelectedCarriers,
    watchGetBotScreens,
    watchUpdateListing,
    watchGetApprovedBotsForListing,
    watchGetRejectedBotsForListing,
    watchGetSubmittedBotsForListing,
    watchGetBotListingHistory,
    watchGetSubmittedBotsForVerification,
    watchApproveRejectBotForVerification,
    watchDeleteBots,
    watchGetBotCreationPreviewSaga,
    watchGetBrandDetailsSagaPreview,
    watchGetVerifiedBotDetailsSaga,
    watchDeleteTemplate,
    watchDeleteDocs,
    watchGetTestDevicesSaga,
    watchAddTestDevicesSaga,
    watchDeleteTestDevicesSaga,
    watchSendMessageSaga,
    watchApproveRejectBotForCreation,
    watchInsertAgent,
    watchKRCSCreation,
    watchGetTemplateDetailsSaga,
    watchGetAgentInfo,
    watchMaaPAgent,
    watchGetTemplateHistorySaga,
    watchUpdateRCSBotOnboardingSaga,
    watchGetAllCampaigns,
    watchGetBotAndTEmplates,
    watchGenerateOtp,
    watchValidateOtp,
    watchTestTemplate,
    watchGetTestSummarySaga,
    watchRetestTemplateSaga,
    watchAgentMsgTypeList,
    watchGetTemplateJSONSaga,
    watchGetDownloadUploadedContacts,
    watchGetBillingDetailedReport,
    watchGetTrafficTableData,
    watchGetFilterData,
    watchGetDetailedRCSTrafficReportSaga,
    watchGetDetailedExcelReportSaga,
    watchGetFailedCounts,
    watchGetP2AMessageCounts,
    watchGetP2AResponseCounts,
    watchGetDetailedDashboardReportSaga,
    watchGetDeliveryTableData,
    watchGetDeliveredCounts,
  ]);
}
