import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";


const TAG = "billingDetailedReport.js";

function* workerGetBillingDetailedReport(
  payload
) {
  try {
    const response = yield call(
      Api.makeSecurePostRequestWithFileSaveOrWithEmailWithExtendedTimeout,
      API_ENDPOINT.GET_BILLING_DETAILED_REPORT,
      //"https://devanalytics.dotgo.com/analytics/secure/getRichPromotionDetailedXlsx",
      payload.data,
      payload.fileInfo.fileName,
      payload.fileInfo.fileExtension
    );
    yield put(
      memberAction.getBillingDetailedReportSuccess(
        response
      )
    );
  } catch (error) {
    yield put(
      memberAction.getBillingDetailedReportFailed(
        error.message
      )
    );
  }
}

export const watchGetBillingDetailedReport =
  takeLatest(
    memberType.GET_BILLING_DETAILED_REPORT_REQUESTED,
    workerGetBillingDetailedReport
  );
