import Service from "../service/Service";
import SecureService from "../service/SecureService";
import { PRO_API_URL } from "../store/URLs/ApiEndpoints";

/**
 * Makes GET request without token.
 * @async
 * @param {String} url API Endpoint.
 * @returns Promise of Axios GET call
 */
export const makeUnsecureGetRequest = (url = "") => {
  return Service.getCall(url);
};
/**
 * Makes POST request without token.
 * @async
 * @param {String} url API Endpoint.
 * @param {Object} requestData Payload.
 * @returns Promise of Axios POST call
 */
export const makeUnsecuredPostRequest = (url = "", requestData = {}) => {
  return Service.postCall(url, requestData);
};
/**
 * Makes GET request with token.
 * @async
 * @param {String} url API Endpoint.
 * @returns Promise of Axios GET call
 */
export const makeSecuredGetRequest = (url = "") => {
  return SecureService.getCall(url);
};
/**
 * Makes POST request with token.
 * @async
 * @param {String} url API Endpoint.
 * @param {Object} requestPayload Payload.
 * @returns Promise of Axios POST call
 */
export const makeSecuredPostRequest = (url = "", requestPayload = {}) => {
  return SecureService.postCall(url, requestPayload);
};
export const makeNewSecuredPostRequest = (url = "", requestPayload = {}) => {
  return SecureService.postCallNew(url, requestPayload);
};
export const makePostRequestForMultipart = (url = "", requestData = {}) => {
  return SecureService.postCallWithMultipart(url, requestData);
};
export const fetchGeoLocation = () => {
  let response = Service.getCall(PRO_API_URL);
 
  return response;
};

export const makeSecuredDeleteRequest = (url = "") => {
  return SecureService.deleteCall(url)
};

export const makeSecurePostRequestWithFileSave = (
  url,
  data = {},
  fileName,
  fileExtension
) => {
   console.log("making request url", url);
   console.log("making request payload", data);
  return SecureService.postCallWithFileSave(
    url,
    data,
    fileName,
    fileExtension
  );
};

export const makeSecurePostRequestWithFileSaveOrWithEmail = (
  url,
  data = {},
  fileName,
  fileExtension
) => {
   console.log("makeSecurePostRequestWithFileSaveOrWithEmail : making request url", url);
   console.log("makeSecurePostRequestWithFileSaveOrWithEmail : making request payload", data);
   if(data.sendEmail === 0){
    return SecureService.postCallWithFileSave(url, data, fileName, fileExtension)
   } else if (data.sendEmail === 1){
    return SecureService.postCall(url, data)
   }
};

export const makeSecurePostRequestWithFileSaveOrWithEmailWithExtendedTimeout = (
  url,
  data = {},
  fileName,
  fileExtension
) => {
   console.log("makeSecurePostRequestWithFileSaveOrWithEmail : making request url", url);
   console.log("makeSecurePostRequestWithFileSaveOrWithEmail : making request payload", data);
   if(data.sendEmail === 0){
    return SecureService.postCallWithFileSave(url, data, fileName, fileExtension)
   } else if (data.sendEmail === 1){
    return SecureService.postCallWithExtendedTimeout(url, data)
   }
};
