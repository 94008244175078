import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useQuery } from "../../store/hooks/useQuery";
import { isTokenValid } from "../../utils/otherUtils";
import SignInRedirect from "../../components/util/SignInRedirect";
import { requestDeveloperLogin } from "../../constants/constant";
/**
 * @author Sumant K
 * Wrapper for routes that requires authentication.
 * Checks token is valid or not depending on it will allow to-
 * define private route.
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
  let query = useQuery();

  useEffect(() => {
    if (query.get("action")) {
      if (
        query.get("action").match("logout") ||
        query.get("action").match("resetPassword") ||
        query.get("status").match("success")
      ) {
        // rest.history.push("/");
        // window.location.replace(process.env.REACT_APP_DEVELOPER_LANDING_PAGE)
        console.log("requestDeveloperLogin called in ProtectedRoute")
        requestDeveloperLogin()
      }
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isTokenValid() ? (
          <Component {...props} />
        ) : query.get("action") ? (
          // <Redirect to="/" />
          <SignInRedirect whichSignIn = "developers"/>
        ) : (
          <SignInRedirect />
        )
      }
    />
  );
};
export default withRouter(React.memo(ProtectedRoute));
