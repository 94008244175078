import { useEffect } from "react";
import { SetPasswordPage } from "../../../../components/Pages/SignupPages/Index";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createActionOf } from "../../../../store/actions/ActionCreator";
import { STATUS_CODE_SUCCESS } from "../../../../constants/constant";
import { SET_PASSWORD_REQUESTED } from "../../../../store/actions/ActionType";
/**
 * Gets redirected from email with token in address bar.
 * Requires token for further flow.
 */
const SetPassword = props => {
  const dispatch = useDispatch();

  const onSetPassword = data => {
    console.info("[Form data] setpasword", data);
    let temp = props.location.search;
    let breakindex = temp.indexOf("=");
    let token = temp.substring(breakindex + 1);
    dispatch(
      createActionOf(SET_PASSWORD_REQUESTED, 0, {
        token: token,
        password: data.new_password,
      })
    );
  };

  const response = useSelector(state => ({
    isLoading: state.setPasswordReducer.isLoading,
    statusCode: state.setPasswordReducer.statusCode,
    statusMessage: state.setPasswordReducer.message,
    error: state.setPasswordReducer.error,
    errorMessage: state.setPasswordReducer.errorMessage,
  }));
  useEffect(() => {
    if (response.statusCode === STATUS_CODE_SUCCESS) {
      console.log("successfully set password");
      // props.history.push({
      //   pathname: "/developer/setup-user",
      //   state: { status: "ok", isAuthorized: true },
      // });
    } else {
      return console.error("error while setting password", response.message);
    }
  }, [response.statusCode]);

  return (
      <SetPasswordPage
        error={response.error}
        errorMessage={response.errorMessage}
        onSetPassword={onSetPassword}
        serverResponse={response}
        domHistory={props.history}
        statusCode={response.statusCode}
        isLoading={response.isLoading}
      />
  );
};
export default withRouter(SetPassword);
