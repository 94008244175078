import { LOADING } from "../../constants/constant";
import {
  GET_DELIVERED_COUNTS_REQUESTED,
  GET_DELIVERED_COUNTS_SUCCESS,
  GET_DELIVERED_COUNTS_FAILED,
  GET_DELIVERED_COUNTS_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getDeliveredCountsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DELIVERED_COUNTS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_DELIVERED_COUNTS_SUCCESS:
      let deliveredMessageData = Object.assign({}, state);
      deliveredMessageData.status = action.data.status_message;
      deliveredMessageData.isLoading = false;
      deliveredMessageData.statusCode = action.data.status_code;
      deliveredMessageData.response = action.data.data;
      return deliveredMessageData;

    case GET_DELIVERED_COUNTS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_DELIVERED_COUNTS_RESET:
      return initialState;

    default:
      return state;
  }
}