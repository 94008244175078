import { LOADING } from "../../constants/constant";
import {
  GET_P2A_RESPONSE_COUNTS_REQUESTED,
  GET_P2A_RESPONSE_COUNTS_SUCCESS,
  GET_P2A_RESPONSE_COUNTS_FAILED,
  GET_P2A_RESPONSE_COUNTS_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getP2AResponseCountsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_P2A_RESPONSE_COUNTS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_P2A_RESPONSE_COUNTS_SUCCESS:
      let failedMessageData = Object.assign({}, state);
      failedMessageData.status = action.data.status_message;
      failedMessageData.isLoading = false;
      failedMessageData.statusCode = action.data.status_code;
      failedMessageData.response = action.data.data;
      return failedMessageData;

    case GET_P2A_RESPONSE_COUNTS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_P2A_RESPONSE_COUNTS_RESET:
      return initialState;

    default:
      return state;
  }
}
