import { StrictMode } from "react"
import { render } from "react-dom"
import App from "./App"
import rootReducer from "./store/reducers/rootReducer"
import rootSaga from "./store/sagas/rootSaga"
import createSagaMiddleware from "redux-saga"
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import { composeWithDevTools } from "redux-devtools-extension"

const reduxComposeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const sagaMiddleware = createSagaMiddleware()
const IS_PRODUCTION =
  process.env.REACT_APP_ENVIRONMENT === "production"
  // process.env.REACT_APP_ENVIRONMENT === "devrcs"
  // process.env.REACT_APP_ENVIRONMENT === "staging"

export const store = createStore(
  rootReducer,
  IS_PRODUCTION
    ? applyMiddleware(sagaMiddleware)
    : reduxComposeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

if (IS_PRODUCTION) {
  console.log = () => {}
  console.info = () => {}
}

render(
  <Provider store={store}>
    <StrictMode>
      <App />
    </StrictMode>
  </Provider>,
  document.getElementById("root")
)
