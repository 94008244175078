import { put, takeLatest, call } from "redux-saga/effects";
import {

    GET_ALL_TRAFFIC_LISTS_REQUESTED,
    GET_ALL_TRAFFIC_LISTS_SUCCESS,
    GET_ALL_TRAFFIC_LISTS_FAILED,
    GET_ALL_TRAFFIC_LISTS_RESET,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import {
  makeSecuredPostRequest,
  makeSecuredGetRequest,
} from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

const TAG = "getFilterDataSaga.js";

function* workerGetFilterDataLists({data={}}) {
  try {
    // let endpoint = API_ENDPOINT.GET_ALL_FILTER_DATA_FOR_RCS_TRAFFIC;

    // if(data.whoIsThis==="super-admin" || data.whoIsThis==="vi-admin") endpoint = API_ENDPOINT.GET_ALL_FILTER_DATA_FOR_RCS_TRAFFIC;

    const response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_ALL_FILTER_DATA_FOR_RCS_TRAFFIC,
      data.payload
    );
    
    yield put(createActionOf(GET_ALL_TRAFFIC_LISTS_SUCCESS, 1, response));
  } catch (error) {
    console.error(TAG, "Worker get all lists for failed: " + error.message);
    yield put(createActionOf(GET_ALL_TRAFFIC_LISTS_FAILED, 2, error));
  }
}


export const watchGetFilterData = takeLatest(
    GET_ALL_TRAFFIC_LISTS_REQUESTED,
    workerGetFilterDataLists
)