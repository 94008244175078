import { LOADING } from "../../../constants/constant";
import {
  REINVITE_USER_FAILED,
  REINVITE_USER_REQUESTED,
  REINVITE_USER_RESET,
  REINVITE_USER_SUCCESS,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getReInviteUserReducer(state = initialState, action) {
  switch (action.type) {
    case REINVITE_USER_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.reInviteUserIsLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case REINVITE_USER_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "REInvite user reducer => Case => Success: while getting category list: " +
          JSON.stringify(action.data)
      );
      userRes.reInviteUserStatus = action.data.status_message;
      userRes.reInviteUserStatusCode = action.data.status_code;
      userRes.reInviteUserIsLoading = false;
      // userRes.reInviteUserMessage = action.data.message;
      userRes.reInviteUserData = action.data.data;

      return userRes;

    case REINVITE_USER_FAILED:
      console.error(
        "Invite User Reducer => Case => Error: while getting category list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case REINVITE_USER_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
