import { LOADING } from "../../../constants/constant"
import {
  TEST_TEMPLATE,
  TEST_TEMPLATE_SUCCESS,
  TEST_TEMPLATE_FAILED,
  TEST_TEMPLATE_RESET
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: true,
}

export default function testTemplteReducer(state = initialState, action) {
  switch (action.type) {
    case TEST_TEMPLATE:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState

    case TEST_TEMPLATE_SUCCESS:
      let userRes = Object.assign({}, state)
      userRes.status = action.data.status_message
      userRes.isLoading = false
      userRes.statusCode = action.data.status_code
      userRes.testTemplateId = action.data.data
      console.log("action.data.data------",action.data.data)

      return userRes

    case TEST_TEMPLATE_FAILED:
      return Object.assign({}, state, action.error)

    case TEST_TEMPLATE_RESET:
        return initialState

    default:
      return state
  }
}
