import { LOADING, STATUS_CODE_SUCCESS } from "../../../../constants/constant"
import {
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS,
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_RESET,
  ADMIN_INSERT_AGENT,
  ADMIN_INSERT_AGENT_SUCCESS,
  ADMIN_INSERT_AGENT_FAILED,
  ADMIN_INSERT_AGENT_RESET,
  ADMIN_INSERT_MaaP_AGENT,
  ADMIN_INSERT_MaaP_AGENT_SUCCESS,
  ADMIN_INSERT_MaaP_AGENT_FAILED,
  ADMIN_INSERT_MaaP_AGENT_RESET,
  ADMIN_KRCS_CREATION,
  ADMIN_KRCS_CREATION_SUCCESS,
  ADMIN_KRCS_CREATION_FAILED,
  ADMIN_KRCS_CREATION_RESET,
  GET_AGENT_INFO,
  GET_AGENT_INFO_SUCCESS,
  GET_AGENT_INFO_FAILED,
  GET_AGENT_INFO_RESET,
} from "../../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: null,
}

export function adminApproveRejectForCreation(state = initialState, action) {
  switch (action.type) {
    case ADMIN_APPROVE_REJECT_BOT_FOR_CREATION: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.statusMessage

      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      return userRes
    }
    case ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_CREATION_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function adminKRCSCreation(state = initialState, action) {
  switch (action.type) {
    case ADMIN_KRCS_CREATION: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_KRCS_CREATION_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action?.data?.statusMessage

      userRes.statusCode = action?.data?.status_code
      if (userRes?.statusCode === STATUS_CODE_SUCCESS) {
        userRes.data = action?.data?.data
      } else {
        userRes.isError = true
        userRes.errorMessage = action?.data?.message ?? "Something went wrong."
      }
      userRes.isLoading = false
      return userRes
    }
    case ADMIN_KRCS_CREATION_FAILED: {

      const errorRes = {
        error: action?.error?.data,
        isLoading: false,
        isError:true
      }
      return errorRes;
    }

    case ADMIN_KRCS_CREATION_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function adminInsertAgent(state = initialState, action) {
  switch (action.type) {
    case ADMIN_INSERT_AGENT: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_INSERT_AGENT_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.statusMessage

      userRes.statusCode = action.data.status_code
      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        userRes.isError = false
        userRes.errorMessage = null
        userRes.data = action.data.data
      } else {
        userRes.isError = true
        userRes.errorMessage = action.data.message ?? "Something went wrong."
      }
      userRes.isLoading = false
      return userRes
    }
    case ADMIN_INSERT_AGENT_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_INSERT_AGENT_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function adminInsertMaaPAgent(state = initialState, action) {
  switch (action.type) {
    case ADMIN_INSERT_MaaP_AGENT: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case ADMIN_INSERT_MaaP_AGENT_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.statusMessage
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        userRes.data = action.data.data
        userRes.error = false
        userRes.errorMessage = null
      } else {
        userRes.error = true
        userRes.errorMessage = action.data.message ?? "Something went wrong."
      }
      return userRes
    }
    case ADMIN_INSERT_MaaP_AGENT_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case ADMIN_INSERT_MaaP_AGENT_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function adminGetAgentInfo(state = initialState, action) {
  switch (action.type) {
    case GET_AGENT_INFO: {
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      return reqstState
    }

    case GET_AGENT_INFO_SUCCESS: {
      let userRes = Object.assign({}, state)
      userRes.status = action.data.statusMessage
      userRes.statusCode = action.data.status_code
      userRes.isLoading = false
      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        userRes.data = action.data.data
      } else {
        userRes.error = true
        userRes.errorMessage = action.data.message ?? "Something went wrong."
      }
      return userRes
    }
    case GET_AGENT_INFO_FAILED: {
      return Object.assign({}, state, action.error)
    }

    case GET_AGENT_INFO_RESET: {
      return initialState
    }

    default:
      return state
  }
}
