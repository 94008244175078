import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import EmailVerify from "../DotGoDevelopers/Public/Signup/EmailVerify";
import SetPassword from "../DotGoDevelopers/Public/Signup/SetPassword";
import PrivacyPolicy from "../../components/Pages/PrivacyPolicy";
import TermsOfUse from "../../components/Pages/TermsOfUse";
import { CountryAndCarrierContainer } from "../CountryAndCarrierContainer/CountryAndCarrierContainer";
import RBMPolicies from "../../components/Shared/RBMPolicies";
import { lazy, Suspense, useEffect } from "react";
import RBMTerms from "../../components/Shared/RBMTerms";
import Fallback from "../../components/Layout/Fallback";
import { ComingSoonVI } from "../../components/Shared/ComingSoonVI";
import ErrorBoundaryFallback from "../../components/util/ErrorBoundaryFallback";
import PageNotFound from "../../components/Pages/PageNotFound";
const HealthMonitor = lazy(() =>
  import("../../components/Shared/HealthMonitorStatus")
);

/**
 * A Route wrapper.
 *
 * Contains all the public routes.
 *
 * Base path is "/" (absolute)
 */
const PublicRoutes = ({ history }) => {
  const { path } = useRouteMatch(); // path -> "/"

  useEffect(() => {
    // legacy path redirection
    if (history.location.pathname === "/terms-of-use") {
      history.replace("/developer-tos");
    }
    if (history.location.pathname === "/privacy-policy") {
      history.replace("/developer-pp");
    }
  }, [history]);

  // ↓ Here Switch is required since only of the component will be rendered matching exact path.
  return (
    <Switch>
      <Route path={`${path}email-verify`} component={EmailVerify} exact />
      <Route path={`${path}set-password`} component={SetPassword} exact />
      <Route path={`${path}developer-pp`} component={PrivacyPolicy} exact />
      <Route path={`${path}developer-tos`} component={ComingSoonVI} exact />
      {/* TermsOfUse */}
      <Route
        path={`${path}country-carrier-page`}
        component={CountryAndCarrierContainer}
        exact
      />
      <Route path={`${path}rbm-policies`} component={ComingSoonVI} exact />
      {/* RBMPolicies */}
      <Route path={`${path}rbm-terms`} component={ComingSoonVI} exact />
      <Route path={`${path}404`} component={PageNotFound} exact />
      {/* RBMTerms */}
      <Suspense fallback={<Fallback />}>
        <Route path={`${path}monitor`} component={HealthMonitor} exact />
        <Route path={`${path}error`} component={ErrorBoundaryFallback} exact />
      </Suspense>
    </Switch>
  );
};
export default withRouter(PublicRoutes);
