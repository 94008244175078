import { put, takeLatest, call } from "redux-saga/effects";
import {
    GET_BILLING_SUMMARY_ANALYTICS,
    GET_BILLING_SUMMARY_ANALYTICS_SUCCESS,
    GET_BILLING_SUMMARY_ANALYTICS_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerAnalyticsBillingSummary(payLoad) {
  let response = {};
  try {
    // if (payLoad.data.whoIsThis === "super-admin") {
    //   response = yield call(
    //     makeSecuredPostRequest,
    //     API_ENDPOINT.DOTGO_ANALYTICS_API,
    //     payLoad.data
    //   );
    // } 
    // else if (payLoad.data.whoIsThis === "vi-admin") {
    //   response = yield call(
    //     makeSecuredPostRequest,
    //     API_ENDPOINT.GET_DASHBOARD_CARRIER_ANALYTICS_API,
    //     payLoad.data
    //   );
    // } 

    // response = {
    //   data: {
    //     "status_code": 10,
    //     "status_message": "Success",
    //     "message": null,
    //     "data": [
    //         {
    //             "msgType": "basic_message",
    //             "domesticCnt": 0,
    //             "intlCnt": 0,
    //             "totalCnt": 0
    //         },
    //         {
    //             "msgType": "a2p_conversation",
    //             "domesticCnt": 1,
    //             "intlCnt": 99999,
    //             "totalCnt": 10000
    //         },
    //         {
    //             "msgType": "p2a_conversation",
    //             "domesticCnt": 5,
    //             "intlCnt": 4,
    //             "totalCnt": 9
    //         },
    //     {
    //             "msgType": "single_message",
    //             "domesticCnt": 1,
    //             "intlCnt": 0,
    //             "totalCnt": 1
    //         },
    //     {
    //           "msgType": "revenue",
    //           "domesticCnt": 65,
    //           "intlCnt": 5,
    //           "totalCnt": 70
    //         },
    //     ]
    // }
    
    // }
    
      response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.ADMIN_ANALYTICS_BILLING_SUMMARY, //We will call this API for both Super Admin & Carrier (VI) Admin (Summary Card)
        payLoad.data
      );
    
    yield put(createActionOf(GET_BILLING_SUMMARY_ANALYTICS_SUCCESS, 1, response));
  } catch (error) {
    console.error("Worker: analytics billing summary failed: " + error.message);
    yield put(createActionOf(GET_BILLING_SUMMARY_ANALYTICS_FAILED, 2, error));
  }
}

export const watchAnalyticsBillingSummary = takeLatest(
  GET_BILLING_SUMMARY_ANALYTICS,
  workerAnalyticsBillingSummary
);
