import { LOADING } from "../../../constants/constant";
import {
  GET_BILLING_TABULAR_VIEW_ANALYTICS,
  GET_BILLING_TABULAR_VIEW_ANALYTICS_SUCCESS,
  GET_BILLING_TABULAR_VIEW_ANALYTICS_FAILED,
  GET_BILLING_TABULAR_VIEW_ANALYTICS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function analyticsBillingTabularViewReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BILLING_TABULAR_VIEW_ANALYTICS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BILLING_TABULAR_VIEW_ANALYTICS_SUCCESS:
      let filteredData = Object.assign({}, state);
      filteredData.status = action.data.message;
      filteredData.isLoading = false;
      filteredData.statusCode = action.data.status_code;
    //   filteredData.cardSummary = action.data.data.finalMap
    //     ? action.data.data.finalMap
    //     : [];
    //   filteredData.tableData = action.data.data.summaryList
    //     ? action.data.data.summaryList
    //     : [];

      filteredData.tableData = action.data.data.data ? action.data.data.data : [];
      filteredData.tableTotal = action.data.data.total ? action.data.data.total : {}
      console.log("Hey can you see me", filteredData.tableData)
      return filteredData;

    case GET_BILLING_TABULAR_VIEW_ANALYTICS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_BILLING_TABULAR_VIEW_ANALYTICS_RESET:
      return initialState;

    default:
      return state;
  }
}
