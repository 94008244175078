import * as action from "./ActionType";
export function setupEnterpriseDetails(data) {
  return { type: action.SETUP_ENTERPRISE_DETAILS_REQUESTED, data: data };
}
export function setupEnterpriseDetailsSuccess(data) {
  return { type: action.SETUP_ENTERPRISE_DETAILS_SUCCESS, data: data };
}
export function setupEnterpriseDetailsFailed(data) {
  return { type: action.SETUP_ENTERPRISE_DETAILS_FAILED, data: data };
}

export function partnerDetails() {
  return { type: action.PARTNER_DETAILS_REQUESTED };
}
export function partnerDetailsSuccess(data) {
  return { type: action.PARTNER_DETAILS_SUCCESS, data: data };
}
export function partnerDetailsFailed(data) {
  return { type: action.PARTNER_DETAILS_FAILED, data: data };
}
export function partnerDetailsReset(data) {
  return { type: action.PARTNER_DETAILS_RESET };
}
export function botListRequested(data) {
  return { type: action.BOT_LIST_REQUESTED, data: data };
}
export function botListSuccess(data) {
  return { type: action.BOT_LIST_SUCCESS, data: data };
}
export function botListFailed(data) {
  return { type: action.BOT_LIST_FAILED, data: data };
}
export function botListReset(data) {
  return { type: action.BOT_LIST_RESET, data: data };
}

export function catagoryListRequested(data) {
  return { type: action.CATEGORY_LIST_REQUESTED, data: data };
}
export function catagoryListSuccess(data) {
  return { type: action.CATEGORY_LIST_SUCCESS, data: data };
}
export function catagoryListFailed(data) {
  return { type: action.CATEGORY_LIST_FAILED, data: data };
}

export function carrierListRequested() {
  return { type: action.CARRIER_LIST_REQUESTED };
}
export function carrierListSuccess(data) {
  return { type: action.CARRIER_LIST_SUCCESS, data: data };
}
export function carrierListFailed(data) {
  return { type: action.CARRIER_LIST_FAILED, data: data };
}

export function countryListRequested() {
  return { type: action.COUNTRY_LIST_REQUESTED };
}
export function countryListSuccess(data) {
  return { type: action.COUNTRY_LIST_SUCCESS, data: data };
}
export function countryListFailed(data) {
  return { type: action.COUNTRY_LIST_FAILED, data: data };
}

export function submitRCSBot(data) {
  return { type: action.SUBMIT_RCS_BOT, data: data };
}
export function submitRCSBotSuccess(data) {
  return { type: action.SUBMIT_RCS_BOT_SUCCESS, data: data };
}
export function submitRCSBotFailed(data) {
  return { type: action.SUBMIT_RCS_BOT_FAILED, data: data };
}

export function submitRCSBotReset() {
  return { type: action.SUBMIT_RCS_BOT_RESET };
}

export function updateRCSBot(data) {
  return { type: action.UPDATE_RCS_BOT, data: data };
}
export function updateRCSBotSuccess(data) {
  return { type: action.UPDATE_RCS_BOT_SUCCESS, data: data };
}
export function updateRCSBotFailed(data) {
  return { type: action.UPDATE_RCS_BOT_FAILED, data: data };
}

export function updateRCSBotReset() {
  return { type: action.UPDATE_RCS_BOT_RESET };
}

export function submitWhatsAppBot(data) {
  return { type: action.SUBMIT_WHATSAPP_BOT, data: data };
}
export function submitWhatsAppBotSuccess(data) {
  return { type: action.SUBMIT_WHATSAPP_BOT_SUCCESS, data: data };
}
export function submitWhatsAppBotFailed(data) {
  return { type: action.SUBMIT_WHATSAPP_BOT_FAILED, data: data };
}

export function submitWhatsAppBotReset() {
  return { type: action.SUBMIT_WHATSAPP_BOT_RESET };
}

export function updateWhatsAppBot(data) {
  return { type: action.UPDATE_WHATSAPP_BOT, data: data };
}
export function updateWhatsAppBotSuccess(data) {
  return { type: action.UPDATE_WHATSAPP_BOT_SUCCESS, data: data };
}
export function updateWhatsAppBotFailed(data) {
  return { type: action.UPDATE_WHATSAPP_BOT_FAILED, data: data };
}

export function updateWhatsAppBotReset() {
  return { type: action.UPDATE_WHATSAPP_BOT_RESET };
}

export function brandListRequested(id) {
  return { type: action.GET_BRANDS_REQUESTED, id };
}
export function brandListuccess(data) {
  return { type: action.GET_BRANDS_SUCCESS, data: data };
}
export function brandListFailed(data) {
  return { type: action.GET_BRANDS_FAILED, data: data };
}

export function confirmRCSSubmission(data) {
  return { type: action.CONFIRM_RCS_SUBMITION, data: data };
}
export function confirmRCSSubmissionSuccess(data) {
  return { type: action.CONFIRM_RCS_SUBMITION_SUCCESS, data: data };
}
export function confirmRCSSubmissionFailed(data) {
  return { type: action.CONFIRM_RCS_SUBMITION_FAILED, data: data };
}

export function confirmRCSSubmissionReset() {
  return { type: action.CONFIRM_RCS_SUBMITION_RESET };
}

export function confirmWhatsAppSubmission(data) {
  return { type: action.CONFIRM_WHATSAPP_SUBMITION, data: data };
}
export function confirmWhatsAppSubmissionSuccess(data) {
  return { type: action.CONFIRM_WHATSAPP_SUBMITION_SUCCESS, data: data };
}
export function confirmWhatsAppSubmissionFailed(data) {
  return { type: action.CONFIRM_WHATSAPP_SUBMITION_FAILED, data: data };
}

export function getBotDetails(data) {
  return { type: action.GET_BOT_DETAILS, data: data };
}

export function getBotDetailsReset() {
  return { type: action.GET_BOT_DETAILS_RESET };
}
export function getBotDetailsSuccess(data) {
  return { type: action.GET_BOT_DETAILS_SUCCESS, data: data };
}

export function getBotDetailsFailed(data) {
  return { type: action.GET_BOT_DETAILS_FAILED, data: data };
}
// export function confirmWhatsAppSubmission(data) {
//   return { type: action.CONFIRM_WHATSAPP_SUBMITION, data: data };
// }
// export function confirmWhatsAppSubmissionSuccess(data) {
//   return { type: action.CONFIRM_WHATSAPP_SUBMITION_SUCCESS, data: data };
// }
// export function confirmWhatsAppSubmissionFailed(data) {
//   return { type: action.CONFIRM_WHATSAPP_SUBMITION_FAILED, data: data };
// }

export function getBotWhatsAppDetails(data) {
  return { type: action.GET_BOT_WHATSAPP_DETAILS, data: data };
}

export function getBotWhatsAppDetailsSuccess(data) {
  return { type: action.GET_BOT_WHATSAPP_DETAILS_SUCCESS, data: data };
}

export function getBotWhatsAppDetailsFailed(data) {
  return { type: action.GET_BOT_WHATSAPP_DETAILS_FAILED, data: data };
}

export function PartnersBrandListRequested(data) {
  return { type: action.PARTNER_BRANDS_LIST_REQUESTED, data: data };
}
export function PartnersBrandListSuccess(data) {
  return { type: action.PARTNER_BRANDS_LIST_SUCCESS, data: data };
}
export function PartnersBrandListFailed(data) {
  return { type: action.PARTNER_BRANDS_LIST_FAILED, data: data };
}

export function getAllIndustryTypesRequested() {
  return { type: action.GET_ALL_INDUSTRY_TYPES_REQUESTED };
}
export function getAllIndustryTypesSuccess(data) {
  return { type: action.GET_ALL_INDUSTRY_TYPES_SUCCESS, data: data };
}
export function getAllIndustryTypesFailed(data) {
  return { type: action.GET_ALL_INDUSTRY_TYPES_FAILED, data: data };
}

export function submitBrandRequested(data) {
  return { type: action.SUBMIT_BRAND_REQUESTED, data: data };
}
export function submitBrandSuccess(data) {
  return { type: action.SUBMIT_BRAND_SUCCESS, data: data };
}
export function submitBrandFailed(data) {
  return { type: action.SUBMIT_BRAND_FAILED, data: data };
}

export function resetSubmitBrandState() {
  return { type: action.SUBMIT_BRAND_RESET };
}

export function getBrandRequested(data) {
  return { type: action.GET_SINGLE_BRAND_REQUESTED, data: data };
}
export function getBrandSuccess(data) {
  return { type: action.GET_SINGLE_BRAND_SUCCESS, data: data };
}
export function getBrandFailed(data) {
  return { type: action.GET_SINGLE_BRAND_FAILED, data: data };
}
export function getBrandReset(data) {
  return { type: action.GET_SINGLE_BRAND_RESET, data: data };
}

export function updateBrandRquested(data) {
  return { type: action.UPDATE_BRAND_REQUESTED, data: data };
}
export function updateBrandSuccess(data) {
  return { type: action.UPDATE_BRAND_SUCCESS, data: data };
}
export function updateBrandFailed(data) {
  return { type: action.UPDATE_BRAND_FAILED, data: data };
}

export function resetUpdateBrandState() {
  return { type: action.RESET_STATE };
}

export function updatePartnersProfileRequested(data) {
  return { type: action.UPDATE_PARTNERS_PROFILE_REQUESTED, data: data };
}

export function updatePartnersProfileFailed(data) {
  return { type: action.UPDATE_PARTNERS_PROFILE_FAILED, data: data };
}

export function updatePartnersProfileSuccess(data) {
  return { type: action.UPDATE_PARTNERS_PROFILE_SUCCESS, data: data };
}

export function clickToListRequested(data) {
  return { type: action.CLICK_TO_LIST_REQUESTED, data: data };
}
export function clickToListSuccess(data) {
  return { type: action.CLICK_TO_LIST_SUCCESS, data: data };
}
export function clickToListFailed(data) {
  return { type: action.CLICK_TO_LIST_FAILED, data: data };
}

export function clickToListReset() {
  return { type: action.CLICK_TO_LIST_RESET };
}

export function clickToUnlistRequested(data) {
  return { type: action.CLICK_TO_UNLIST_REQUESTED, data: data };
}
export function clickToUnlistSuccess(data) {
  return { type: action.CLICK_TO_UNLIST_SUCCESS, data: data };
}
export function clickToUnlistFailed(data) {
  return { type: action.CLICK_TO_UNLIST_FAILED, data: data };
}

export function clickToUnlistReset() {
  return { type: action.CLICK_TO_UNLIST_RESET };
}

export function pricingRequested(data) {
  return { type: action.PRICING_REQUESTED, data: data };
}
export function pricingSuccess(data) {
  return { type: action.PRICING_SUCCESS, data: data };
}
export function pricingFailed(data) {
  return { type: action.PRICING_FAILED, data: data };
}

export function pricingReset() {
  return { type: action.PRICING_RESET };
}

export function changePasswordRequested(data) {
  return { type: action.CHANGE_PASSWORD_REQUESTED, data: data };
}
export function changePasswordSuccess(data) {
  return { type: action.CHANGE_PASSWORD_SUCCESS, data: data };
}
export function changePasswordFailed(data) {
  return { type: action.CHANGE_PASSWORD_FAILED, data: data };
}

export function inviteUserRequested(data) {
  return { type: action.INVITE_USER_REQUESTED, data: data };
}
export function inviteUserSuccess(data) {
  return { type: action.INVITE_USER_SUCCESS, data: data };
}
export function inviteUserFailed(data) {
  return { type: action.INVITE_USER_FAILED, data: data };
}

export function inviteUserReset() {
  return { type: action.INVITE_USER_RESET };
}

export function getAllUsersRequested(data) {
  return { type: action.GET_ALL_USERS_REQUESTED };
}
export function getAllUsersSuccess(data) {
  return { type: action.GET_ALL_USERS_SUCCESS, data: data };
}
export function getAllUsersFailed(data) {
  return { type: action.GET_ALL_USERS_FAILED, data: data };
}

export function getAllUsersReset() {
  return { type: action.GET_ALL_USERS_RESET };
}

export function reInviteUserRequested(data) {
  return { type: action.REINVITE_USER_REQUESTED, data: data };
}
export function reInviteUserSuccess(data) {
  return { type: action.REINVITE_USER_SUCCESS, data: data };
}
export function reInviteUserFailed(data) {
  return { type: action.REINVITE_USER_FAILED, data: data };
}

export function reInviteUserReset() {
  return { type: action.REINVITE_USER_RESET };
}

export function deleteUserRequested(data) {
  return { type: action.DELETE_USER_REQUESTED, data: data };
}
export function deleteUserSuccess(data) {
  return { type: action.DELETE_USER_SUCCESS, data: data };
}
export function deleteUserFailed(data) {
  return { type: action.DELETE_USER_FAILED, data: data };
}

export function deleteUserReset() {
  return { type: action.DELETE_USER_RESET };
}

export function setBillingUserPasswordRequested(data) {
  return { type: action.SET_BILLING_USER_PASSWORD_REQUESTED, data: data };
}
export function setBillingUserPasswordSuccess(data) {
  return { type: action.SET_BILLING_USER_PASSWORD_SUCCESS, data: data };
}
export function setBillingUserPasswordFailed(data) {
  return { type: action.SET_BILLING_USER_PASSWORD_FAILED, data: data };
}

/**
 * Creates action object for all redux actions.
 * @param {String} action type of action to dispatch.
 * @param {Number} type Type of action 0: Request, 1: Success, 2:Failed, 3: Reset.
 * @param {Object} data Payload for the dispatched action.
 */

export function carrierGetSubmittedBrandsRequested(data) {
  return { type: action.CARRIER_GET_SUBMITTED_BRANDS_REQESTED, data: data };
}
export function carrierGetSubmittedBrandsSuccess(data) {
  return { type: action.CARRIER_GET_SUBMITTED_BRANDS_SUCCESS, data: data };
}
export function carrierGetSubmittedBrandsFailed(data) {
  return { type: action.CARRIER_GET_SUBMITTED_BRANDS_FAILED, data: data };
}

export function carrierGetSubmittedBrandsReset() {
  return { type: action.CARRIER_GET_SUBMITTED_BRANDS_RESET };
}

export function carrierGetRejectedBrandsRequested(data) {
  return { type: action.CARRIER_GET_REJECTED_BRANDS_REQESTED, data: data };
}
export function carrierGetRejectedBrandsSuccess(data) {
  return { type: action.CARRIER_GET_REJECTED_BRANDS_SUCCESS, data: data };
}
export function carrierGetRejectedBrandsFailed(data) {
  return { type: action.CARRIER_GET_REJECTED_BRANDS_FAILED, data: data };
}

export function carrierGetRejectedBrandsReset() {
  return { type: action.CARRIER_GET_REJECTED_BRANDS_RESET };
}

export function carrierGetApprovedBrandsRequested(data) {
  return { type: action.CARRIER_GET_APPROVED_BRANDS_REQESTED, data: data };
}
export function carrierGetApprovedBrandsSuccess(data) {
  return { type: action.CARRIER_GET_APPROVED_BRANDS_SUCCESS, data: data };
}
export function carrierGetApprovedBrandsFailed(data) {
  return { type: action.CARRIER_GET_APPROVED_BRANDS_FAILED, data: data };
}

export function carrierGetApprovedBrandsReset() {
  return { type: action.CARRIER_GET_APPROVED_BRANDS_RESET };
}
export function ApproveOrRejectBrandRequested(data) {
  return {
    type: action.APPROVE_OR_REJECT_CARRIER_BRANDS_REQUESTED,
    data: data,
  };
}
export function ApproveOrRejectBrandSuccess(data) {
  return { type: action.APPROVE_OR_REJECT_CARRIER_BRANDS_SUCCESS, data: data };
}
export function ApproveOrRejectBrandFailed(data) {
  return { type: action.APPROVE_OR_REJECT_CARRIER_BRANDS_FAILED, data: data };
}

export function ApproveOrRejectBrandReset() {
  return { type: action.APPROVE_OR_REJECT_CARRIER_BRANDS_RESET };
}
/**
 * Creates a action for redux state.
 * @param {string} action Action to dispatch.
 * @param {number} type Type of dispatch: is it request, success or failed?
 * @param {object} data Data to send for updating redux state.
 * type 0: for requests
 * type 1: for success response
 * type 2: for failed response
 * type 3: for reset state
 */
export const createActionOf = (action = "", type = 0, data = {}) => {
  switch (type) {
    case 0: {
      let payload = { type: action };
      if (data) {
        payload.data = data;
      }
      return payload;
    }
    case 1: {
      let payload = { type: action };
      payload.data = data;
      return payload;
    }
    case 2: {
      let payload = { type: action };
      payload.data = data;
      return payload;
    }
    case 3: {
      let payload = { type: action };
      return payload;
    }
    default:
      return;
  }
};

export function loadBotReview(data) {
  return { type: action.LOAD_BOT_REVIEWS, data: data };
}
export function loadBotReviewSuccess(data) {
  return { type: action.LOAD_BOT_REVIEWS_SUCCESS, data: data };
}
export function loadBotReviewFailed(data) {
  return { type: action.LOAD_BOT_REVIEWS_FAILED, data: data };
}

export function submitBotReview(data) {
  return { type: action.SEND_BOT_REVIEWS, data: data };
}
export function submitBotReviewSuccess(data) {
  return { type: action.SEND_BOT_REVIEWS_SUCCESS, data: data };
}
export function submitBotReviewFailed(data) {
  return { type: action.SEND_BOT_REVIEWS_FAILED, data: data };
}

export function addRichTempalte(data) {
  return { type: action.ADD_RICH_TEMPLATE, data: data };
}
export function addRichTemplateSuccess(data) {
  return { type: action.ADD_RICH_TEMPLATE_SUCCESS, data: data };
}
export function addRichTemplateFailed(data) {
  return { type: action.ADD_RICH_TEMPLATE_FAILED, data: data };
}
export function addRichTemplateReset() {
  return { type: action.ADD_RICH_TEMPLATE_RESET, data: {} };
}
export function fetchGeoLocation(data) {
  return { type: action.FETCH_GEO_LOCATION, data: data };
}
export function fetchGeoLocationSuccess(data) {
  return { type: action.FETCH_GEO_LOCATION_SUCCESS, data: data };
}
export function fetchGeoLocationFailed(data) {
  return { type: action.FETCH_GEO_LOCATION_FAILED, data: data };
}

export function getBotCreationPreviewRequested(data) {
  return {
    type: action.GET_BOT_CREATION_PREVIEW_REQUESTED,
    data: data,
  };
}
export function getBotCreationPreviewSuccess(data) {
  return { type: action.GET_BOT_CREATION_PREVIEW_SUCCESS, data: data };
}
export function getBotCreationPreviewFailed(data) {
  return { type: action.GET_BOT_CREATION_PREVIEW_FAILED, data: data };
}
export function getBotCreationPreviewReset() {
  return { type: action.GET_BOT_CREATION_PREVIEW_RESET };
}

export function getBrandRequestedPreview(data) {
  return { type: action.GET_SINGLE_BRAND_REQUESTED_PREVIEW, data: data };
}
export function getBrandSuccessPreview(data) {
  return { type: action.GET_SINGLE_BRAND_SUCCESS_PREVIEW, data: data };
}
export function getBrandFailedPreview(data) {
  return { type: action.GET_SINGLE_BRAND_FAILED_PREVIEW, data: data };
}
export function getBrandResetPreview() {
  return { type: action.GET_SINGLE_BRAND_RESET_PREVIEW };
}

export function getVerifiedBotDetails(data) {
  return { type: action.GET_VERIFIED_BOT_DETAILS, data: data };
}

export function getVerifiedBotDetailsReset() {
  return { type: action.GET_VERIFIED_BOT_DETAILS_RESET };
}
export function getVerifiedBotDetailsSuccess(data) {
  return { type: action.GET_VERIFIED_BOT_DETAILS_SUCCESS, data: data };
}

export function getVerifiedBotDetailsFailed(data) {
  return { type: action.GET_VERIFIED_BOT_DETAILS_FAILED, data: data };
}

export function getTestDevicesRequested(data) {
  return { type: action.GET_TEST_DEVICES_REQUESTED, data: data };
}

export function getTestDevicesReset() {
  return { type: action.GET_TEST_DEVICES_RESET };
}
export function getTestDevicesSuccess(data) {
  return { type: action.GET_TEST_DEVICES_SUCCESS, data: data };
}

export function getTestDevicesFailed(data) {
  return { type: action.GET_TEST_DEVICES_FAILED, data: data };
}
export function addTestDevicesRequested(bot_id, data) {
  return {
    type: action.ADD_TEST_DEVICES_REQUESTED,
    bot_id: bot_id,
    data: data,
  };
}

export function addTestDevicesReset() {
  return { type: action.ADD_TEST_DEVICES_RESET };
}
export function addTestDevicesSuccess(data) {
  return { type: action.ADD_TEST_DEVICES_SUCCESS, data: data };
}

export function addTestDevicesFailed(data) {
  return { type: action.ADD_TEST_DEVICES_FAILED, data: data };
}
export function deleteTestDevicesRequested(data) {
  return { type: action.DELETE_TEST_DEVICES_REQUESTED, data: data };
}

export function deleteTestDevicesReset() {
  return { type: action.DELETE_TEST_DEVICES_RESET };
}
export function deleteTestDevicesSuccess(data) {
  return { type: action.DELETE_TEST_DEVICES_SUCCESS, data: data };
}

export function deleteTestDevicesFailed(data) {
  return { type: action.DELETE_TEST_DEVICES_FAILED, data: data };
}

export function sendMessageRequested(data) {
  return { type: action.SEND_MESSAGE_REQUESTED, data: data };
}

export function sendMessageReset() {
  return { type: action.SEND_MESSAGE_RESET };
}
export function sendMessageSuccess(data) {
  return { type: action.SEND_MESSAGE_SUCCESS, data: data };
}

export function sendMessageFailed(data) {
  return { type: action.SEND_MESSAGE_FAILED, data: data };
}

export function updateRCSBotONBRequested(data) {
  return { type: action.UPDATE_RCS_BOT_ONB_REQUESTED, data: data };
}

export function updateRCSBotONBReset() {
  return { type: action.UPDATE_RCS_BOT_ONB_RESET };
}
export function updateRCSBotONBSuccess(data) {
  return { type: action.UPDATE_RCS_BOT_ONB_SUCCESS, data: data };
}

export function updateRCSBotONBFailed(data) {
  return { type: action.UPDATE_RCS_BOT_ONB_FAILED, data: data };
}

export function getTemplateRequested(data, isVIAdmin) {
  return {
    type: action.GET_SINGLE_TEMPLATE_REQUESTED,
    data: { data: data, isVIAdmin },
  };
}
export function getTemplateSuccess(data) {
  return { type: action.GET_SINGLE_TEMPLATE_SUCCESS, data: data };
}
export function getTemplateFailed(data) {
  return { type: action.GET_SINGLE_TEMPLATE_FAILED, data: data };
}
export function getTemplateReset(data) {
  return { type: action.GET_SINGLE_TEMPLATE_RESET, data: data };
}

export function approveOrRejectTemplateRequested(data) {
  return {
    type: action.APPROVE_OR_REJECT_DOTGO_TEMPLATE_FOR_ADMIN,
    data: data,
  };
}
export function getAdminCampaign(data, isVIAdmin) {
  return { type: action.GET_ADMIN_CAMPAIGN_REQUESTED, data: data, isVIAdmin };
}

export function getRichPromotionsAndNotificationsCampaignSummaryRequested(
  data
) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_REQUESTED,
    data: data,
  };
}
export function getRichPromotionsAndNotificationsCampaignSummarySuccess(data) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_SUCCESS,
    data: data,
  };
}
export function getRichPromotionsAndNotificationsCampaignSummaryFailed(data) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY_FAILED,
    data: data,
  };
}
export function resetRichPromotionsAndNotificationsCampaignSummary() {
  return {
    type: action.RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_SUMMARY,
  };
}
export function getRichPromotionsAndNotificationsCampaignDetailedReportRequested(
  data,
  fileInfo
) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}
export function getRichPromotionsAndNotificationsCampaignDetailedReportSuccess(
  data
) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_SUCCESS,
    data: data,
  };
}
export function getRichPromotionsAndNotificationsCampaignDetailedReportFailed(
  data
) {
  return {
    type: action.GET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT_FAILED,
    data: data,
  };
}
export function resetRichPromotionsAndNotificationsCampaignDetailedReport() {
  return {
    type: action.RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT,
  };
}
export function agentMessageTypeListRequested(data) {
  return { type: action.GET_AGENT_MSG_TYPE_REQUESTED, data: data };
}

export function agentMessageTypeListReset() {
  return { type: action.GET_AGENT_MSG_TYPE_RESET };
}
export function agentMessageTypeListSuccess(data) {
  return { type: action.GET_AGENT_MSG_TYPE_SUCCESS, data: data };
}

export function agentMessageTypeListFailed(data) {
  return { type: action.GET_AGENT_MSG_TYPE_FAILED, data: data };
}

export function getDownloadUploadedContactsRequested(data, fileInfo) {
  return {
    type: action.GET_DOWNLOAD_UPLOADED_CONTACTS_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}
export function getDownloadUploadedContactsSuccess(data) {
  return { type: action.GET_DOWNLOAD_UPLOADED_CONTACTS_SUCCESS, data: data };
}
export function getDownloadUploadedContactsFailed(data) {
  return { type: action.GET_DOWNLOAD_UPLOADED_CONTACTS_FAILED, data: data };
}

export function getBillingDetailedReportRequested(data, fileInfo) {
  return {
    type: action.GET_BILLING_DETAILED_REPORT_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}
export function getBillingDetailedReportSuccess(data) {
  return {
    type: action.GET_BILLING_DETAILED_REPORT_SUCCESS,
    data: data,
  };
}
export function getBillingDetailedReportFailed(data) {
  return {
    type: action.GET_BILLING_DETAILED_REPORT_FAILED,
    data: data,
  };
}
export function resetBillingDetailedReport() {
  return {
    type: action.RESET_RICH_PROMOTIONS_AND_NOTIFICATIONS_CAMPAIGN_DETAILED_REPORT,
  };
}

//action creator for RCS Traffic Dashboard
export function getDetailedRCSTrafficReport(data, fileInfo) {
  return {
    type: action.DOWNLOAD_DETAILED_RCS_TRAFFIC_REPORT_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}

export function getDetailedExcelReport(data, fileInfo) {
  return {
    type: action.DOWNLOAD_DETAILED_EXCEL_REPORT_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}

//action creator for RCS Detailed Dashboard
export function getDetailedDashboardReport(data, fileInfo) {
  return {
    type: action.DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_REQUESTED,
    data: data,
    fileInfo: fileInfo,
  };
}
