import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_DELIVERED_COUNTS_REQUESTED,
  GET_DELIVERED_COUNTS_SUCCESS,
  GET_DELIVERED_COUNTS_FAILED,
} from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../appApi/AppApi";
import { createActionOf } from "../actions/ActionCreator";

function* workerGetDeliveredCounts(payLoad) {
  let response = {};
  try {    
    response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_DELIVERED_COUNTS, 
      payLoad.data
    );    
    yield put(createActionOf(GET_DELIVERED_COUNTS_SUCCESS, 1, response));
  } catch (error) {
    yield put(createActionOf(GET_DELIVERED_COUNTS_FAILED, 2, error));
  }
}

export const watchGetDeliveredCounts = takeLatest(
  GET_DELIVERED_COUNTS_REQUESTED,
  workerGetDeliveredCounts
);
