import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

const TAG = "CampaignBriefDetails.js";

function* workerGetDownloadUploadedContacts(payload) {
  // alert(JSON.stringify(id));
  // console.log(
  //   TAG,
  //   "worker get workerGetCountryCarrierList : " + JSON.stringify()
  // );
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.GET_DOWNLOAD_UPLOADED_CONTACTS,
      payload.data.data,
      payload.data.fileInfo.fileName,
      payload.data.fileInfo.fileExtension
    );

    yield put(memberAction.getDownloadUploadedContactsSuccess(response));
  } catch (error) {
    // console.log(TAG, "workerGetCountryCarrierList: " + error.message);
    yield put(memberAction.getDownloadUploadedContactsFailed(error.message));
  }
}

export const watchGetDownloadUploadedContacts = takeLatest(
  memberType.GET_DOWNLOAD_UPLOADED_CONTACTS_REQUESTED,
  workerGetDownloadUploadedContacts
);
