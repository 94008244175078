import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi"


const TAG = "getDetailedExcelReportSaga.js";

function* workerGetDetailedExcelReport(
  payload
) {
  try {
    const response = yield call(
      Api.makeSecurePostRequestWithFileSaveOrWithEmailWithExtendedTimeout,
      API_ENDPOINT.GET_DETAILED_EXCEL_REPORT,
      payload.data,
      payload.fileInfo.fileName,
      payload.fileInfo.fileExtension
    );
    yield put(memberAction.createActionOf(memberType.DOWNLOAD_DETAILED_EXCEL_REPORT_SUCCESS, 1, response));
  } catch (error) {
    yield put(memberAction.createActionOf(memberType.DOWNLOAD_DETAILED_EXCEL_REPORT_SUCCESS, 2, error));
  }
}

export const watchGetDetailedExcelReportSaga =
  takeLatest(
    memberType.DOWNLOAD_DETAILED_EXCEL_REPORT_REQUESTED,
    workerGetDetailedExcelReport
  );
