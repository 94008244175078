import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { regexEmail } from "../../../utils/Regex";
import FormError from "../../Errors/FormError";

const HomePage = ({ onEmailSubmit, onSignIn, ...rest }) => {
  const { handleSubmit, errors, register } = useForm();
  const toTermsOfUse = () => {
    // don't want to disturb styles by adding NavLink
    window.open("/developer-tos", "_blank");
  };

  return (
    <>
      {/* <section className="hero-section align-center"> */}
      <div className="container body-margin-top">
        <div className="row">
          <div className="col-md-6 aggregator-index padding-top-35">
            <h4 className="font-size-18 font-color-gray margin-top-0">
              <strong className="margin-top-0">
                Thank you for your interest in
              </strong>
            </h4>
            <h2 className="margin-top-10">
              <img
                alt="image"
                className="img-fluid"
                src="images/ViRBM.svg"
                width="50px"
                style={{
                  // height: "46px",
                  width: "160px",
                  position: "relative",
                }}
              />{" "}
            </h2>
            <p className="font-size-16 font-color-gray margin-top-35">
              Get access to RBM APIs to send and receive RCS messages to/from
              India RCS users.{" "}
            </p>
            {/* {" "}<span className="pri-clr" style={ {fontWeight: "bolder"}}>India</span> */}
            <p className="font-size-16 font-color-gray margin-top-35">
              Submit your bot for Brand Verification and bot validation in order
              to launch on VI.
            </p>
            {/* <img
                  alt="image"
                  className="img-fluid"
                  src="images/logo-hero.svg"
                  width="55px"
                  style={{
                    height: "22px",
                    width: "24px",
                    position: "relative",
                    marginLeft: "7px",
                  }}
                />{" "}<span className="pri-clr" style={ {fontWeight: "bolder"}}>India</span> */}
          </div>
          <div className=" col-md-5 " style={{ minHeight: "566px" }}>
            <div class="row vi-yl-card">
              <div class="col-md-12">
                <form
                  className="signup-form"
                  onSubmit={handleSubmit(onEmailSubmit)}
                >
                  <div className="head">
                    <p>Let’s get started!</p>
                    <h2>Create your free account.</h2>
                  </div>
                  <div className="input-group">
                    <div className="signup-email">
                      <label htmlFor>
                        <i
                          className="fa fa-envelope font-size-18"
                          aria-hidden="true"
                        />
                        Email ID<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        autoComplete="new-password"
                        ref={register({ required: true, pattern: regexEmail })}
                        name={"user_email"}
                        type="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        id="user-signup-email"
                      />
                      {rest.errorMessage && (
                        <div style={{ color: "#EE2737", marginTop: "55px" }}>
                          <FormError errorMessage="A user with this email address already exists" />
                        </div>
                      )}

                      {errors.user_email &&
                        !rest.errorMessage &&
                        errors.user_email.type === "required" && (
                          <div style={{ color: "#EE2737", marginTop: "55px" }}>
                            <FormError errorMessage="Email is required" />
                          </div>
                        )}

                      {errors.user_email &&
                        !rest.errorMessage &&
                        errors.user_email.type === "pattern" && (
                          <div style={{ color: "#EE2737", marginTop: "55px" }}>
                            <FormError errorMessage="Invalid Email" />
                          </div>
                        )}
                      {/* <div id="errorMsg" className="error-highlight margin-top-10">Please enter a valid Email ID</div> */}
                    </div>
                    <div className="info form-info">
                      <p>
                        <i
                          className="fa fa-exclamation-circle font-size-20"
                          aria-hidden="true"
                        />
                        Your email id will be used as User ID for login. Kindly
                        use your official email id only.
                      </p>
                    </div>
                    <div className="accept-terms">
                      <input
                        defaultChecked
                        ref={register({ required: true })}
                        name={"tc_checkbox"}
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="form-info" style={{ cursor: "allowed" }}>
                        I accept the{" "}
                        <a onClick={toTermsOfUse} style={{ cursor: "pointer" }}>
                          Terms and Conditions
                        </a>
                        .
                      </span>
                      {errors.tc_checkbox &&
                        errors.tc_checkbox.type === "required" && (
                          <div style={{ color: "#EE2737", marginTop: "5px" }}>
                            <FormError errorMessage="Please accept Terms and Conditions" />
                          </div>
                        )}
                    </div>
                    <div className="input-group-append">
                      <button type="reset" className=" sec-btn ">
                        reset
                      </button>{" "}
                      &nbsp;{" "}
                      <button
                        type="submit"
                        className=" pri-btn"
                        style={{ border: "none" }}
                      >
                        sign up
                        <span hidden={!rest.isLoading}>&nbsp;</span>
                        <Loader
                          inverted
                          active={rest.isLoading}
                          inline
                          size="tiny"
                        />
                      </button>
                      <p className="form-ft-link">
                        Already have an account?{" "}
                        <a
                          onClick={onSignIn}
                          style={{ cursor: "pointer" }}
                          className="link-crimson"
                        >
                          sign in
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container-fluid margin-bottom-40 margin-top-40 padding-right-0 font-color-light-gray">
            <div className="box-orange-bdr">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="customer-support-icon">
                    <img
                      src="images/vi_icon_customer_support.svg"
                      width={43}
                      alt="Customer Support"
                    />
                  </div>
                  <div className="customer-support-text font-size-14">
                    <p className="margin-bottom-0">
                      Not sure how to setup your account or stuck somewhere?
                      <br />
                      let our customer support team assist you, contact us via
                      call or email and we will do our best to resolve your
                      issues.
                    </p>
                    <br />
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-4 font-size-16">
                    <div className="">
                      <p className="font-weight-300">Contact number</p>
                      <p><strong>+91-XXXXXXXXXX</strong></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 font-size-16">
                    <p className="font-weight-300">Email</p>
                    <p><strong>rbmsupport@vodafoneidea.com</strong></p>
                  </div> */}
                <div class="col-sm-6 col-xs-12 font-size-16">
                  {/* <div className="customer-support-mobile text-right">
                    <p className="font-weight-300">Contact number</p>
                    <p>
                      <strong>+91-XXXXXXXXXX</strong>
                    </p>
                  </div> */}
                  <div className="customer-support-email">
                    <p className="font-weight-300">Email</p>
                    <p>
                      <strong>virbmsupport@vodafoneidea.com</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </section> */}
    </>
  );
};
export default withRouter(HomePage);
