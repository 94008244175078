import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isTokenValid } from "../../utils/otherUtils";
import { partnerDetails } from "../actions/ActionCreator";

export const usePartnerRole = () => {
  const [role, setRole] = useState();
  const dispatch = useDispatch();
  const isAuthenticated = isTokenValid();

  const response = useSelector((state) => {
    return {
      partnerDetails: state.partnerDetailsReducer.partnerDetails,
      partnerRole: state.partnerDetailsReducer.partnerDetails?.role,
      isLoading: state.partnerDetailsReducer.isLoading,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isAuthenticated) {
      if (!response.partnerDetails) {
        dispatch(partnerDetails());
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (response.partnerDetails?.role) {
      setRole(response.partnerDetails.role);
    }
  }, [response.partnerDetails]);

  return { role: response.partnerRole ?? role, isLoading: response.isLoading };
};
