/**
 * @see https://emailregex.com/
 */
// export const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x20-\x21\x23-\x5b\x5d-\x7f]|\\[\x20\x7f])+")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x20-\x21\x23-\x5a\x53-\x7f]|\\[\x20\x7f])+)\])/;

/**
 * Alphanumeric regex
 */
export const regexPasswordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[0-9])/;
//New regex added for pass validation as above do not work for special chars change made on 9-nov-22 for DGDEVBE-137 by  Sachin K
export const regexPasswordPatternNew=/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const regexURIPattern = /^((https?|ftp|smtp|http):\/\/)?(www.)?[a-z0-9]+[a-z0-9-_]*\.[a-z]+(\/[a-zA-Z0-9#]+\.[a-zA-Z]+\/?)*$/
export const regexZipCodePattern = /^[a-zA-Z]+$/;

export const regexWhiteSpace = /\s/g;
export const regexNonWordChar = /[^\w\s]/gi;
export const regexFirstAndLastName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const regexURL =  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const regexOnlyNo =[0-9]
export const imageUrlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const templateNamePattern = /^([a-zA-Z0-9_]+\s)*[a-zA-Z0-9_]+$/;
// export const templateNamePattern = /^([a-zA-Z_])+\s*[a-zA-Z_]+$/;
export const template_nm = /[a-zA-Z]/;
export const websiteRegex = /((https+):\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})/
export const regexIPV4 = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
