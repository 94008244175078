import { NavLink, useRouteMatch, withRouter } from "react-router-dom";
import { isTokenValid } from "../../utils/otherUtils";

const Header = (props) => {
  // const routeArray = [
  //   "/email-verify",
  //   "/set-password",
  //   "/developer/setup-enterprise",
  //   "/developer/setup-user",
  //   "/signup-success",
  // ]
  const { url, path } = useRouteMatch();
  console.log("url : ", url);
  const activeArray = [
    //
  ];
  const mainArray = [
    "/faq",
    "/Aggregator/documents"
  ];
  return (
    <nav className="navbar navbar-default navbar-fixed-top navbar-bg">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div className="navbar-brand">
            <a href="/">
              <img
                src="images/vi_logo.svg"
                className="vi-navbar-logo-size"
                alt="VI"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Header);
