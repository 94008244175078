import { LOADING } from "../../constants/constant";
import {
  VALIDATE_OTP,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILED,
  VALIDATE_OTP_RESET
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function validateOtpReducer(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_OTP:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case VALIDATE_OTP_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log("Otp validated: ", action);
      try {
        userRes.status = action.data.status
        userRes.message = action.data.message;
        userRes.isLoading = false;
        return userRes;
      } catch (error) {
        throw new Error(error);
      }

    case VALIDATE_OTP_FAILED:
      return Object.assign({}, state, action.error);
    
    case VALIDATE_OTP_RESET:
      return initialState;

    default:
      return state;
  }
}