import { LOADING, STATUS_CODE_SUCCESS } from "../../../constants/constant"
import {
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_LIST_FAILED,
  GET_CAMPAIGN_LIST_RESET,
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  GET_CAMPAIGN_DETAILS_FAILED,
  GET_CAMPAIGN_DETAILS_RESET,
  GET_BOT_TEMPLATES_LIST_REQUESTED,
  GET_BOT_TEMPLATES_LIST_SUCCESS,
  GET_BOT_TEMPLATES_LIST_FAILED,
  GET_BOT_TEMPLATES_LIST_RESET,
  GET_CAMPAIGN_BOT_TEMPLATE_DATA,
  GET_CAMPAIGN_BOT_TEMPLATE_RESET,
} from "../../actions/ActionType"

const initialState = {
  status: "INIT",
  isLoading: null,
  error: null,
  errorMessage: null,
}
export function campaignListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGN_LIST: {
      return { ...state, isLoading: true, status: LOADING }
    }

    case GET_CAMPAIGN_LIST_SUCCESS: {
      let userRes = { ...state }
      userRes.status = action.data.data.statusMessage

      userRes.statusCode = action.data.data.status_code
      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        // console.log("action.data::: ", action.data.data)
        userRes.data = action.data.data?.data ?? []
      } else {
        userRes.error = true
        userRes.errorMessage =
          action.data.data.message ?? "Something went wrong."
      }
      userRes.isLoading = false
      return userRes
    }
    case GET_CAMPAIGN_LIST_FAILED: {
      return { error: true, errorMessage: "Something went wrongs" }
    }

    case GET_CAMPAIGN_LIST_RESET: {
      return initialState
    }

    default:
      return state
  }
}
export function campaignDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGN_DETAILS: {
      return { ...state, isLoading: true, status: LOADING }
    }

    case GET_CAMPAIGN_DETAILS_SUCCESS: {
      let userRes = { ...state }
      userRes.status = action.data.data.statusMessage

      userRes.statusCode = action.data.data.status_code
      if (userRes.statusCode === STATUS_CODE_SUCCESS) {
        // console.log("action.data::: ", action.data.data)
        userRes.data = action.data.data?.data ?? []
      } else {
        userRes.error = true
        userRes.errorMessage =
          action.data.data.message ?? "Something went wrong."
      }
      userRes.isLoading = false
      return userRes
    }
    case GET_CAMPAIGN_DETAILS_FAILED: {
      return { error: true, errorMessage: "Something went wrongs" }
    }

    case GET_CAMPAIGN_DETAILS_RESET: {
      return initialState
    }

    default:
      return state
  }
}
// Reducer to get launched Bot list and its templates
export function botTemplateListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOT_TEMPLATES_LIST_REQUESTED: {
      return { ...state, isLoading: true, status: LOADING }
    }

    case GET_BOT_TEMPLATES_LIST_SUCCESS: {
      let userRes = { ...state }
      userRes.status = action.data.statusMessage
      userRes.statusCode = action.data.status_code
      userRes.data = action.data.data?.data
      userRes.isLoading = false
      return userRes
    }
    case GET_BOT_TEMPLATES_LIST_FAILED: {
      return { error: true, errorMessage: "Something went wrong" }
    }

    case GET_BOT_TEMPLATES_LIST_RESET: {
      return initialState
    }

    default:
      return state
  }
}
// Reducer to persist template name and bot name for AddnewCampaignContainer
export function AddNewCampaignDataReducer(state = {}, action) {
  switch (action.type) {
    
    case GET_CAMPAIGN_BOT_TEMPLATE_DATA: {
      
      return {...state,...action.data}
    }
    case GET_CAMPAIGN_BOT_TEMPLATE_RESET: {
      return {}
    }

    default:
      return state
  }
}

