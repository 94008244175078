import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { requestLogin , requestDeveloperLogin} from "../../constants/constant"
/**
 * Just redirects to SSO sign in page.
 */
const SignInRedirect = ({whichSignIn = "analytics"}) => {
  useEffect(() => {
    switch(whichSignIn){
      case "analytics":
        console.log("requestLogin called in SignInRedirect 1")
        requestLogin()
        break;
      case "developers":
        console.log("requestDeveloperLogin called in SignInRedirect")
        requestDeveloperLogin()
        break;
      default:
        console.log("requestLogin called in SignInRedirect 2")
        requestLogin()
    }
    // requestLogin()
    // requestDeveloperLogin()
  }, [])
  return <div style={{ height: "100vh" }}></div>
}
export default withRouter(SignInRedirect)
