import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../../actions/ActionCreator";
import * as memberType from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import * as Api from "../../../appApi/AppApi"


const TAG = "getDetailedRCSTrafficReportSaga.js";

function* workerGetDetailedRCSTrafficReport(
  payload
) {
  console.log("step 4 :", payload)
  try {
    const response = yield call(
      Api.makeSecurePostRequestWithFileSaveOrWithEmailWithExtendedTimeout,
      API_ENDPOINT.GET_DETAILED_RCS_TRAFFIC_REPORT,
      payload.data,
      payload.fileInfo.fileName,
      payload.fileInfo.fileExtension
    );
    yield put(memberAction.createActionOf(memberType.DOWNLOAD_DETAILED_RCS_TRAFFIC_REPORT_SUCCESS, 1, response));
  } catch (error) {
    yield put(memberAction.createActionOf(memberType.DOWNLOAD_DETAILED_RCS_TRAFFIC_REPORT_FAILED, 2, error));
  }
}

export const watchGetDetailedRCSTrafficReportSaga =
  takeLatest(
    memberType.DOWNLOAD_DETAILED_RCS_TRAFFIC_REPORT_REQUESTED,
    workerGetDetailedRCSTrafficReport
  );
