import { LOADING, STATUS_CODE } from "../../../constants/constant";
import {
    RETEST_TEMPLATE,
    RETEST_TEMPLATE_FAILED,
    RETEST_TEMPLATE_RESET,
    RETEST_TEMPLATE_SUCCESS
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getRetestTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case RETEST_TEMPLATE:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case RETEST_TEMPLATE_SUCCESS:
      let userRes = Object.assign({}, state)
      //userRes.status = action.data.status;
      userRes.isLoading = false
      userRes.status = action.data.data.status_message
      userRes.statusCode = action.data.data.status_code

      userRes.testTemplateId = action.data.data.data
      return userRes

    case RETEST_TEMPLATE_FAILED:
      console.error(
        "getTemplateDetailsReducer => Error: while getting template details: " +
          JSON.stringify(action.data.error)
      )
      return Object.assign({}, state, action.data.error)

    case RETEST_TEMPLATE_RESET:
      return initialState

    default:
      return state
  }
}
