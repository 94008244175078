import NavbarContainer from "../../containers/DotGoDevelopers/Private/Navbar/NavbarContainer"
import { isTokenValid } from "../../utils/otherUtils"
import Footer from "../Shared/Footer"
import Header from "../Shared/Header"

const Layout = (props) => {
  return (
    <>
      <div style={{minHeight:"97vh", paddingBottom:"30px"}}>
        {isTokenValid() ? <NavbarContainer /> : <Header />}
        {props.children}
      </div>

      <Footer />
    </>
  )
}
export default Layout
