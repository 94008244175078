import { Component } from "react";
import { withRouter } from "react-router-dom";
import "./ErrorBoundary.css";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback";
/**
 * A fallback class component(Functional component not supported) to avoid white screen (App crashing), and for error reporting.
 * * @see {@link https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries|ErrorBoundary}
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error("ErrorBoundary ErrorInfo: ", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary Error: ", error.message);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback isCrashed={this.state.hasError} />;
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
