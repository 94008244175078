import { put, takeLatest, call } from 'redux-saga/effects';
import * as memberAction from '../../actions/ActionCreator';
import * as memberType from '../../actions/ActionType';
import { API_ENDPOINT } from '../../URLs/ApiEndpoints';
import * as Api from '../../../appApi/AppApi';

const TAG = 'getDetailedDashboardReportSaga.js';

function* workerGetDetailedDashboardReport(payload) {
  console.log('step 4 :', payload);
  try {
    const response = yield call(
      Api.makeSecurePostRequestWithFileSaveOrWithEmailWithExtendedTimeout,
      API_ENDPOINT.GET_DETAILED_DASHBOARD_REPORT,
      payload.data,
      payload.fileInfo.fileName,
      payload.fileInfo.fileExtension
    );
    yield put(
      memberAction.createActionOf(
        memberType.DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_SUCCESS,
        1,
        response
      )
    );
  } catch (error) {
    yield put(
      memberAction.createActionOf(
        memberType.DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_FAILED,
        2,
        error
      )
    );
  }
}

export const watchGetDetailedDashboardReportSaga = takeLatest(
  memberType.DOWNLOAD_DETAILED_RCS_DETAILED_REPORT_REQUESTED,
  workerGetDetailedDashboardReport
);
