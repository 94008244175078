import { JSO } from "../utils/jso/src/JSO"
// import Cookies from "js-cookies"
import cookie from "js-cookie"
import { getSubDomain } from "../utils/otherUtils"
export * from "./campaignConstants"

export const LOADING = "LOADING"
export const SUCCESS = "SUCCESS"
export const ERROR = "ERROR"
export const STATUS_CODE = 102 // this status is for client purpose

export const STATUS_CODE_SUCCESS = 10
export const STATUS_CODE_FAILED = 11
export const STATUS_CODE_INVALID = 12
export const STATUS_CODE_CUSTOM = 13

export const CARRIER_APPROVAL_VERIFIED = 19
export const CARRIER_APPROVAL_PENDING = 18
export const CARRIER_APPROVAL_REJECTED = 20
// Bot status
export const BOT_STATUS_SUBMITTED_FOR_LISTING = 54
export const BOT_STATUS_UNDER_REVIEW = 1
export const BOT_STATUS_CHANGE_UNDER_REVIEW = 2
export const BOT_STATUS_LISTED = 3
export const BOT_STATUS_UNLISTED = 4
export const BOT_STATUS_REJECTED = 5
export const BOT_STATUS_SUBMITTED_FOR_LAUNCH = 6
export const BOT_STATUS_LAUNCHED = 10
export const BOT_STATUS_LAUNCH_FAILED = 9
export const BOT_STATUS_IN_PROGRESS = -1
export const BOT_STATUS_FORWARD_VI = 109
// New Statuses
export const DOTGO_BOT_STATUS_FORWARDED = 30 //Forwarded to carrier by Dotgo(Rule 2)
export const DOTGO_BOT_STATUS_APPROVED = 31 //Approved by Dotgo(Rule 2)
export const DOTGO_BOT_STATUS_REJECTED = 32 //Rejected by Dotgo(Rule 2)
export const DOTGO_STATUS_BOT_TO_BE_APPROVED_BY_CARRIER = 39 //Directly submitted to carrier without Dotgo intervention(Rule 1)
export const DOTGO_STATUS_BOT_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER = 42 //Approved by dotgo and submitted to carrier for approval(Rule 3)
export const VI_STATUS_BOT_REJECTED_BY_CARRIER = 111 //Approved by dotgo and rejected by carrier
export const VI_STATUS_BOT_APPROVED_BY_CARRIER = 53 //Approved by dotgo and Approved by carrier
//

export const AGGREGATOR_STATUS_SUBMITTED_FOR_LAUNCH = 18
export const AGGREGATOR_STATUS_LAUNCHED = 19
export const AGGREGATOR_STATUS_LAUNCH_FAILED = 20
// New Status:
export const AGGREGATOR_STATUS_TO_BE_APPROVED_BY_DOTGO = 70 // 36-STATUS_AGGREGATOR_FORWARDED_BY_DOTGO(Rule 1)
export const AGGREGATOR_STATUS_FORWARDED_BY_DOTGO = 36 // 36-STATUS_AGGREGATOR_FORWARDED_BY_DOTGO(Rule 1)
export const AGGREGATOR_STATUS_TO_BE_APPROVED_BY_CARRIER = 41 // 41-STATUS_AGGREGATOR_TO_BE_APPROVED_BY_CARRIER(Rule 2)
export const AGGREGATOR_STATUS_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER = 44 // 44-STATUS_AGGREGATOR_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER(Rule 3)
//
// Brand status
export const BRAND_STATUS_VERIFIED = 11
export const BRAND_STATUS_REJECTED = 12
export const BRAND_STATUS_SUBMITTED = 13
export const BRAND_STATUS_FORWARDED_BY_DOTGO = 33 //Forwarded to carrier by Dotgo(Rule 2)
export const BRAND_STATUS_APPROVED_BY_DOTGO = 34 //Approved by Dotgo(Rule 2)
export const BRAND_STATUS_REJECTED_BY_DOTGO = 35 //Rejected by Dotgo(Rule 2)
export const BRAND_STATUS_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER = 43 //Approved by dotgo and submitted to carrier for approval(Rule 3)
export const BRAND_STATUS_TO_BE_APPROVED_BY_CARRIER = 40 //Directly submitted to carrier without Dotgo intervention(Rule 1)
//
export const BOT_CHANNEL_RCS = 1
export const BOT_CHANNEL_WHATSAPP = 2

export const DOTGO_CARRIER_STATUS_FORWARDED = 36
export const DOTGO_CARRIER_STATUS_APPROVED = 37
export const DOTGO_CARRIER_STATUS_REJECTED = 38
export const MAX_CHAR_LENGTH_LOGN_DESC = 2048

console.log("What does env contain", process.env)

let appEnv = {}

appEnv.BASE_URI = process.env.REACT_APP_DEVELOPER_BASE_URI
appEnv.BASE_ANALYTICS_URI = process.env.REACT_APP_BASE_URI
appEnv.ANALYTICS_TO_DEVELOPER_REDIRECT_URI = process.env.REACT_APP_DEVELOPER_REDIRECT_URL
appEnv.RCS_API_BASE_URI = process.env.REACT_APP_BASE_URI
appEnv.AUTH_URI = process.env.REACT_APP_AUTH_URI
appEnv.IMAGE_URI = process.env.REACT_APP_IMAGE_BASE_URI
appEnv.DOCS_URI = process.env.REACT_APP_DOCS_BASE_URI
appEnv.IMAGE_BASE_URI = process.env.REACT_APP_IMAGE_BASE_URI
appEnv.REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI
appEnv.CLIENT_ID = process.env.REACT_APP_CLIENT_ID
appEnv.DEVELOPER_CLIENT_ID = process.env.REACT_APP_DEVELOPER_CLIENT_ID
appEnv.LOGOUT_URI = process.env.REACT_APP_LOGOUT_URI
appEnv.ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
appEnv.SIGN_IN_URL = process.env.REACT_APP_SIGN_IN_URI
appEnv.ANALYTICS_REPORT_REDIRECT = process.env.REACT_APP_ANALYTICS_PORTAL_URL
export const config = appEnv

export const JSO_CLIENT_CONFIG = {
  providerID: "custom-provider",
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  authorization: process.env.REACT_APP_AUTH_URI,
  scopes: { name: process.env.REACT_APP_SCOPES },
}

export const JSO_DEVELOPER_CLIENT_CONFIG = {
  providerID: "custom-provider",
  client_id: process.env.REACT_APP_DEVELOPER_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_DEVELOPER_REDIRECT_URI,
  authorization: process.env.REACT_APP_AUTH_URI,
  scopes: { name: process.env.REACT_APP_SCOPES },  
}

export const requestLogin = () => {
  try {
    cookie.remove("dotgo-token", { domain: getSubDomain() });
    if (/developer\/dashboard/.test(window.location.href)) {
      // TODO: if need such case
    } else {
      localStorage.setItem(
        "__callback-url",
        encodeURIComponent(window.location.href)
      );
    }
    const client = new JSO(JSO_CLIENT_CONFIG);
    client.getToken().then((token) => {
      console.log("");
    });
  } catch (error) {}
};

export const requestDeveloperLogin = () => {
  try {
    cookie.remove("dotgo-token", { domain: getSubDomain() });
    if (/developer\/dashboard/.test(window.location.href)) {
      // TODO: if need such case
    } else {
      localStorage.setItem(
        "__callback-url",
        encodeURIComponent(window.location.href)
      );
    }
    const client = new JSO({
      providerID: "custom-provider",
      client_id: process.env.REACT_APP_DEVELOPER_CLIENT_ID,
      redirect_uri:  process.env.REACT_APP_DEVELOPER_REDIRECT_URI,
      authorization: process.env.REACT_APP_AUTH_URI,
      scopes: { name: process.env.REACT_APP_SCOPES },
    });
    client.getToken().then((token) => {
      console.log("");
    });
  } catch (error) {}
};

export function refreshToken() {
  cookie.remove("dotgo-token", { domain: ".dotgo.com" });
  let client = new JSO(JSO_DEVELOPER_CLIENT_CONFIG);
  client.getToken().then((token) => {});
}

/**
 * Error: clear cookies instead of localStorage.
 * @param {Event} event optional
 */
export const requestLogout = (event) => {
  let client = new JSO(JSO_CLIENT_CONFIG);
  client.wipeTokens();
  localStorage.removeItem("state-dotgo");
  localStorage.removeItem("activeTab");
  localStorage.removeItem("activeTabAggregator")
  localStorage.clear();
  cookie.remove("dotgo-token", { domain: getSubDomain() });
  cookie.remove("dotgo-token");
  window.location.href = appEnv.LOGOUT_URI + appEnv.CLIENT_ID;
};
export const requestDeveloperLogout = (event) => {
  let client = new JSO(JSO_DEVELOPER_CLIENT_CONFIG);
  client.wipeTokens();
  localStorage.removeItem("state-dotgo");
  localStorage.removeItem("activeTab");
  localStorage.removeItem("activeTabAggregator")
  cookie.remove("dotgo-token", { domain: getSubDomain() });
  cookie.remove("dotgo-token");
  window.location.href = appEnv.LOGOUT_URI + appEnv.DEVELOPER_CLIENT_ID;
};
const launchRoute = {};

launchRoute.GET_STARTED = "/developer/launch/";
launchRoute.COMPANY_DETAILS = "company-details";
launchRoute.SELECT_CARRIER = "select-carrier";
launchRoute.REVIEW_SUBMIT = "review-submit";
const aggregatorLaunchRoute = {};

aggregatorLaunchRoute.BASIC_DETAILS = "basic-details";
aggregatorLaunchRoute.BRAND_DETAILS = "brand-details";
aggregatorLaunchRoute.SELECT_CARRIER = "select-carrier";
aggregatorLaunchRoute.PAYMENT = "payment";
aggregatorLaunchRoute.RCS_DETAILS = "rcs-details";
export const LAUNCH_ROUTES = launchRoute;
export const AGGREGATOR_LAUNCH_ROUTES = aggregatorLaunchRoute;

const localStorageKeys = {};

localStorageKeys.SUBMITTED_RCS_BOT = "submitted_RCS_bot";
localStorageKeys.LAUNCH_SUBMITTED_RCS_BOT = "launch_submitted_RCS_bot";
localStorageKeys.SELECT_CARRIER = "select-carrier";
localStorageKeys.PAYMENT = "payment";
localStorageKeys.RCS_DETAILS = "rcs_details";
localStorageKeys.LAUNCH_STEP_1_4 = "__launch_step_1_4";
localStorageKeys.LAUNCH_STEP_2_4 = "__launch_step_2_4";
localStorageKeys.LAUNCH_STEP_3_4 = "__launch_step_3_4";
localStorageKeys.LAUNCH_STEP_3_4_cp = "__launch_step_3_4_cp";
localStorageKeys.LAUNCH_STEP_4_4 = "__launch_step_4_4";

localStorageKeys.LAUNCH_STEP_1_5 = "__launch_step_1_5";
localStorageKeys.LAUNCH_STEP_2_5 = "__launch_step_2_5";
localStorageKeys.LAUNCH_STEP_3_5 = "__launch_step_3_5";
localStorageKeys.LAUNCH_STEP_4_5 = "__launch_step_4_5";
localStorageKeys.LAUNCH_STEP_4_5_cp = "__launch_step_4_5_cp";
localStorageKeys.LAUNCH_STEP_5_5 = "__launch_step_5_5";

localStorageKeys.CREATE_BOT_1_3 = "__create_bot_1_3";
localStorageKeys.CREATE_BOT_2_3 = "__create_bot_2_3";
localStorageKeys.CREATE_BOT_3_3 = "__create_bot_3_3";

localStorageKeys.RCS_BOT_LOGO = "__rcs_bot_logo";
localStorageKeys.RCS_BRAND_LOGO = "__rcs_brand_logo";
localStorageKeys.RCS_BG_IMAGE = "__rcs_bg_image";

export const LOCAL_STORAGE = localStorageKeys;

// export const BRAND_STATUS_VERIFIED_ADMIN = 13
// export const BRAND_STATUS_REJECTED_ADMIN = 14
// export const BRAND_STATUS_PENDING_APPROVAL = 12
// export const BRAND_STATUS_SUBMITTED_ADMIN = 11

export const stepCfg = () => {
  return [
    {
      title: "Submit Your Bot Details",
      isCompleted: false,
      isActive: true,
      label: "RCS Bot Details",
    },
    {
      title: "Submit Your Brand Details",
      isCompleted: false,
      isActive: false,
      label: "Brand Details",
    },
    // {
    //   title: "Complete Carrier Selection",
    //   isCompleted: false,
    //   isActive: false,
    //   label: "Carrier Selection",
    // },
  ];
};
export const stepVerification = () => {
  return [
    {
      title: "",
      isCompleted: false,
      isActive: true,
      label: "Bot Details & Experience",
    },
    {
      title: "",
      isCompleted: false,
      isActive: false,
      label: "Brand Details",
    },
    {
      title: "",
      isCompleted: false,
      isActive: false,
      label: "Business Verification ",
    },
    {
      title: "",
      isCompleted: false,
      isActive: false,
      label: "Payment",
    },
  ];
};
// BotConsole: Statuses
export const BOT_CONSOLE_BOT_SUBMITTED_FOR_CREATION = 50;
export const BOT_CONSOLE_BOT_CREATION_APPROVED = 65;
export const BOT_CONSOLE_BOT_CREATED = 51;
export const BOT_CONSOLE_BOT_CREATION_REJECTED = 56;
export const BOT_CONSOLE_BOT_SUBMITTED_FOR_VERIFICATION = 52;
export const BOT_CONSOLE_BOT_VERIFIED = 53;
export const BOT_CONSOLE_BOT_VERIFICATION_REJECTED = 55;
export const BOT_CONSOLE_BOT_VERIFICATION_REJECTED_2 = 111;
export const BOT_CONSOLE_VERIFICATION_FORWORD_BY_DOTGO = 108;
export const BOT_CONSOLE_BOT_VERIFICATION_APP_BY_DOTGO_PENDING_CARRIER = 109;
export const BOT_CONSOLE_VERIFICATION_APPROVED_BY_CARRIER = 110;
export const BOT_CONSOLE_BOT_VERIFICATION_REJECTED_BY_CARRIER = 111;
export const BOT_CONSOLE_BOT_LAUNCH_REJECTED_BY_DOTGO_ADMIN = 32;
export const BOT_CONSOLE_BOT_LAUNCH_REJECTED_BY_CARRIER_ADMIN = 9;

export const BOT_CONSOLE_LAUNCH_DETAILS_SUBMITTED = 106;
export const PRE_STATUS_IN_PROCESS = -2;
export const MAX_CHAR_LENGTH = 100
export const MAX_CHAR_LENGTH_25 = 25

// Agent Creation
export const AVAILABLE_MaaPs = [
  {
    maapId: "MPS01",
    maapProviderId: "synchronoss",
    maapMessageFormat: "gsma",
    maapUri:
      "https://mmp-whitelabel.sncrmmp.com/botruntimeservice/v1/bots/public/bot/v1/{botId}/messages",
    serviceProvider: "com.kirusa.rcs.maap.synchronoss.SynchronossCbp",
    capabilityCheckUri:
      "https://mmp-whitelabel.sncrmmp.com/botruntimeservice/v1/bots/public/bot/v1/{botId}/contactCapabilities?userContact={userContact}",
    messageStatusUri:
      "https://mmp-whitelabel.sncrmmp.com/botruntimeservice/v1/bots/public/bot/v1/{botId}/messages/{msgId}/status",
  },

  {
    maapId: "MPV01",
    maapProviderId: "vodafone",
    maapMessageFormat: "vodafone",
    maapUri:
      "https://api.rcs.vodafone.com:8443/a2p/v01/accounts/{account_id}/messaging.sendMessage",
    serviceProvider: "com.kirusa.rcs.maap.vodafone.VodafoneCbp",
    capabilityCheckUri:
      "https://api.rcs.vodafone.com:8443/a2p/v01/accounts/{account_id}/capabilities.query",
    messageStatusUri:
      "https://api.rcs.vodafone.com:8443/a2p/v01/accounts/{account_id}/messaging.revokeMessage",
    testerInviteUri:
      "https://api.rcs.vodafone.com:8443/a2p/v01/accounts/{account_id}/messaging.setComposing",
    eventUri:
      "https://api.rcs.vodafone.com:8443/a2p/v01/accounts/{account_id}/messaging.setMessageStatus",
  },
  {
    maapId: "MPG01",
    maapProviderId: "google",
    maapMessageFormat: "google",
    maapUri:
      "https://{REGION}-rcsbusinessmessaging.googleapis.com/v1/phones/{missdn}/agentMessages?messageId={messageId}",
    serviceProvider: "com.kirusa.rcs.maap.google.RBM",
    capabilityCheckUri:
      "https://{REGION}-rcsbusinessmessaging.googleapis.com/v1/phones/{userContact}/capabilities?requestId={requestId}",
    messageStatusUri:
      "https://{REGION}-rcsbusinessmessaging.googleapis.com/v1/phones/{PHONE_NUMBER}/agentMessages/{MESSAGE_ID}",
    testerInviteUri:
      "https://{REGION}-rcsbusinessmessaging.googleapis.com/v1/phones/{userContact}/testers",
    eventUri:
      "https://{REGION}-rcsbusinessmessaging.googleapis.com/v1/phones/{userContact}/agentEvents?eventId={messageId}}",
  },
  {
    maapId: "MPO01",
    maapProviderId: "orange",
    maapMessageFormat: "gsma",
    maapUri:
      "https://api.orange.com/combo/preprod/v1/gsma/bot/v1/{botId}/messages/",
    serviceProvider: "com.kirusa.rcs.maap.orange.OrangeCbP",
    capabilityCheckUri:
      "https://api.orange.com/combo/preprod/v1/gsma/bot/v1/{botId}/contactCapabilities?userContact={userContact}",
    messageStatusUri:
      "https://api.orange.com/combo/preprod/v1/gsma/bot/v1/{botId}/messages/{msgId}/status",
  },
  {
    maapId: "MPM01",
    maapProviderId: "mavenir",
    maapMessageFormat: "gsma",
    maapUri: "https://bots.rcs.mavenir.com/bot/v1/{botId}/messages",
    serviceProvider: "com.kirusa.rcs.maap.mavenir.MavenirCbp",
    capabilityCheckUri:
      "https://bots.rcs.mavenir.com/bot/v1/{botId}/contactCapabilities?userContact={contactId}",
    messageStatusUri:
      "https://bots.rcs.mavenir.com/bot/v1/{botId}/messages/{msgId}/status",
  },
  {
    maapId: "OMG01",
    maapProviderId: "rbm_open_maap",
    maapMessageFormat: "google",
    maapUri:
      "https://{REGION}-rbmopenmaap.googleapis.com/v1/agents/{AGENT_ID}/phones/{PHONE_NUMBER}/agentMessages?messageId={MESSAGE_ID}",
    serviceProvider: "com.kirusa.rcs.maap.openmaap.RBMOpenMaaP",
    capabilityCheckUri:
      "https://{REGION}-rbmopenmaap.googleapis.com/v1/agents/{AGENT_ID}/phones/{PHONE_NUMBER}/capabilities?requestId={REQUEST_ID}",
    messageStatusUri:
      "https://{REGION}-rbmopenmaap.googleapis.com/v1/agents/{AGENT_ID}/phones/{PHONE_NUMBER}/agentMessages/{MESSAGE_ID}",
    eventUri:
      "https://{REGION}-rbmopenmaap.googleapis.com/v1/agents/{AGENT_ID}/phones/{PHONE_NUMBER}/agentEvents?eventId={EVENT_ID}",
  },
  {
    maapId: "MPJ01",
    maapProviderId: "jio",
    maapMessageFormat: "gsma",
    maapUri:
      "https://chatbot.jio.com/rcsbotsgateway/rcs/bot/v1/{botId}/messages/",
    serviceProvider: "com.kirusa.rcs.maap.jio.JioCbP",
    capabilityCheckUri:
      "https://chatbot.jio.com/rcsbotsgateway/rcs/bot/v1/{botId}/contactCapabilities?userContact={userContact}",
    messageStatusUri:
      "https://chatbot.jio.com/rcsbotsgateway/rcs/bot/v1/{botId}/messages/{msgId}/status",
  },
];

export const stepCfgCarrier = () => {
  return [
    {
      title: "Submit Your Bot Details",
      isCompleted: true,
      isActive: false,
      label: "RCS Bot Details",
    },
    {
      title: "Submit Your Brand Details",
      isCompleted: true,
      isActive: false,
      label: "Brand Details",
    },
    // {
    //   title: "Complete Carrier Selection",
    //   isCompleted: false,
    //   isActive: true,
    //   label: "Carrier Selection",
    // },
  ];
};
export const redirectToPage = (routeHistory = undefined) => {
  const storedURL = localStorage.getItem("__callback-url");
  if (storedURL) {
    if (routeHistory) {
      localStorage.removeItem("__callback-url");
      const callbackHref = decodeURIComponent(storedURL);
      const callbackURL = new URL(callbackHref);

      routeHistory?.replace({
        pathname: callbackURL.pathname,
        search: callbackURL.search,
      });
    } else {
      localStorage.removeItem("__callback-url");
      window.location.href = decodeURIComponent(storedURL);
    }
  } else {
    return;
  }
};