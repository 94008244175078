import axios from "axios";
import { saveAs } from "file-saver";
import cookie from "js-cookie";
import { requestLogin, requestDeveloperLogin } from "../constants/constant";
import {store} from "../index"
import { partnerDetails } from "../store/actions/ActionCreator";
import { ACC_MANAGER, CHILD_AGGREGATOR_READONLY, CHILD_AGGREGATOR_READWRITE, userRoles } from "../constants/stringConstants";
/**
 * Creates axios instance
 * @link https://github.com/axios/axios
 */
let instanceSecure;
let instanceSecureForMultipart;
var instanceSecureWithExtendedTimeout;

export const getToken = () => {
  return new Promise((resolve, reject) => {
    // const token = localStorage.getItem("dotgo-token")
    const token = cookie.get("dotgo-token");

    if (!token) {
      reject("No token found");
    }
    const AUTH_TOKEN = JSON.parse(token)[0].access_token;
    resolve(AUTH_TOKEN);
  });
};

const getRole = (role) => {
  if (!role) {
      return userRoles.AGGREGATOR;
  }
  
  if (role === ACC_MANAGER) {
      return userRoles.ACC_MANAGER;
  }
  
  if (role === CHILD_AGGREGATOR_READWRITE || role === CHILD_AGGREGATOR_READONLY) {
      return userRoles.AGGREGATOR;
  }

  return userRoles.ADMIN;
};

if (!instanceSecureWithExtendedTimeout) {
  instanceSecureWithExtendedTimeout = axios.create({
    timeout: process.env.REACT_APP_EXTENDED_TIMEOUT_IN_MILLISECOND ?? 604800000,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

if (!instanceSecure) {
  instanceSecure = axios.create({
    timeout: 100000,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

if (!instanceSecureForMultipart) {
  instanceSecureForMultipart = axios.create({
    timeout: 100000,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

/**
 * Request Interceptor:
 * It intercept the request,
 * used for making some change in request before
 * it reaches to server.
 */
instanceSecure.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor:
 * It intercept the response,
 * used for making some change in response before
 * it reaches to component who requested it
 */
instanceSecure.interceptors.response.use(
  function (response) {
    if (response?.status === 403 || response?.status === 404) {
      window.location.replace("/error?ref=redirect");
      return;
    }
    return response;
  },
  function (error) {
    const statusCode = error?.response?.status;
    if ((statusCode >= 500 && statusCode <= 599) || statusCode === 403) {
      window.location.replace("/error?ref=redirect");
      return;
    }
    return Promise.reject(error);
  }
);

/**
 * Request Interceptor:
 * It intercept the request, used for making some change in request before
 * it reaches to server.
 */
instanceSecureWithExtendedTimeout.interceptors.request.use(
  function (config) {
    // console.log("interceptors.request -- " + JSON.stringify(config));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor:
 * It intercept the response, used for making some change in response before
 * it reaches to component who requested it
 */
instanceSecureWithExtendedTimeout.interceptors.response.use(
  function (response) {
    if (response?.status === 403 || response?.status === 404) {
      window.location.replace("/error?ref=redirect");
      return;
    }
    return response;
  },
  function (error) {
    const statusCode = error?.response?.status;
    if ((statusCode >= 500 && statusCode <= 599) || statusCode === 403) {
      if(statusCode === 504){
        return Promise.reject(error);
      } else {
        window.location.replace("/error?ref=redirect");
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default class SecureService {
  /**
   * Get Request
   * @param {url} url url for request
   */
  static flowNumber=1;
  static getCall(url) {
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecure
            .get(url, config)
            .then(function (response) {
              // console.log("getCall Response--------------------> " + JSON.stringify(response));
              // console.log("getCall Response--------------------> " + response);
              resolve(response);
            })
            .catch(function (error) {
              console.error("Error[GET] is here 1", SecureService.flowNumber, error.response)
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          console.error("[API CALL] Error:[GET]", e);
          if (e?.response?.status === 401) {
            console.error("Error[GET] is here 2", SecureService.flowNumber++, e.response)
            console.error("[API CALL] Error while connecting server:[401]", e);
            requestDeveloperLogin()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }

  /**
   * Posts Request
   * @param {url} url url for posting request
   * @param {data} data data object to post to server
   */
  
  static postCall(url, data) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecure
            .post(url, dataWithRole, config)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              console.error("Error[POST] is here 1", SecureService.flowNumber, error.response)
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          console.error("[API CALL] Error:[POST]", e);
          if (e?.response?.status === 401) {
            console.error("Error[POST] is here 2", SecureService.flowNumber++, e.response)
            console.error("[API CALL] Error while connecting server:[401]", e);
            window.location.reload()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }

  static postCallWithExtendedTimeout(url, data) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecureWithExtendedTimeout
            .post(url, dataWithRole, config)
            .then(function (response) {
              if(response.status === 204){
                resolve({status:204});
              }
              else{
                resolve(response);
              }
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          console.error("[API CALL] Error:[POST]", e);
          if (e?.response?.status === 401) {
            console.error("[API CALL] Error while connecting server:[401]", e);
            requestDeveloperLogin()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }

  static postCallNew(url, data) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return new Promise(function (resolve, reject) {
      getToken()
        .then((token) => {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          instanceSecure
            .post(url, dataWithRole, config)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              try {
                if (error?.response?.status === 401) {
                  console.error("[API CALL] Error while connecting server:[401]", error);
                  requestDeveloperLogin()
                } else {
                  reject(error.response);
                }
              } catch (error) {
                reject(error);
              }
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  static getCallNew(url) {
    return new Promise(function (resolve, reject) {
      getToken()
        .then((token) => {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          instanceSecure
            .get(url, config)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              try {
                if (error?.response?.status === 401) {
                  console.error("[API CALL] Error while connecting server:[401]", error);
                  requestDeveloperLogin()
                } else {
                  reject(error.response);
                }
              } catch (error) {
                reject(error);
              }
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  static putCall(url, data) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecure
            .put(url, dataWithRole, config)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          if (e?.response?.status === 401) {
            console.error("[API CALL] Error while connecting server:[401]", e);
            requestDeveloperLogin()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }

  static postCallWithMultipart(url, data) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecureForMultipart
            .post(url, dataWithRole, config)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .catch((e) => {
        console.error("[API CALL] Error while connecting server", e);
      });
  }

  /**
   * delete Request
   * @param {url} url url for request
   */
  static deleteCall(url) {
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return new Promise(function (resolve, reject) {
          instanceSecure
            .delete(url, config)
            .then(function (response) {
              // console.log("deleteCall Response--------------------> " + JSON.stringify(response));
              // console.log("deleteCall Response--------------------> " + response);
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          console.error("[API CALL] Error:[GET]", e);
          if (e?.response?.status === 401) {
            console.error("[API CALL] Error while connecting server:[401]", e);
            requestDeveloperLogin()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }

  /**
   * Posts Request
   * @param {url} url url for posting request
   * @param {data} data data object to post to server
   */
  static postCallWithFileSave(url, data, fileName, fileExtension) {
    let partnerDetails = store.getState().partnerDetailsReducer.partnerDetails
    let parentAggregatorId = store.getState().partnerDetailsReducer.parent_aggregator_id
    const dataWithRole = {
      ...data,
      role: getRole(partnerDetails.role),
      partnersId: parentAggregatorId ?? partnerDetails.partner_id,
  };
    return getToken()
      .then((token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        };
        return new Promise(function (resolve, reject) {
          instanceSecure
            .post(url, dataWithRole, config)
            .then(function (response) {
              if(response.status === 204){
                resolve({status:204})
              }
              else{
                resolve(saveAs(response.data, fileName + "." + fileExtension));
              }
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .catch((e) => {
        try {
          console.error("[API CALL] Error:[GET]", e);
          if (e?.response?.status === 401) {
            console.error("[API CALL] Error while connecting server:[401]", e);
            requestDeveloperLogin()
          }
        } catch (error) {
          console.error("[API CALL] Error while connecting server", error);
        }
      });
  }
}
