import { put, takeLatest, call } from "redux-saga/effects";
import * as memberAction from "../actions/ActionCreator";
import * as memberType from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import * as Api from "../../appApi/AppApi";

function* workerBotList(payLoad) {
  try {
    const response = yield call(
      Api.makeSecuredPostRequest,
      API_ENDPOINT.BOT_LIST,
      payLoad.data
    );
    yield put(memberAction.botListSuccess(response));
  } catch (error) {
    yield put(memberAction.botListFailed(error.message));
  }
}

export const watchBotList = takeLatest(
  memberType.BOT_LIST_REQUESTED,
  workerBotList
);
