import { LOADING } from "../../../constants/constant";
import {
    GET_TEMPLATES_FOR_ADMIN_SUCCESS,
    GET_TEMPLATES_FOR_ADMIN,
    GET_TEMPLATES_FOR_ADMIN_FAILED,
    GET_TEMPLATES_FOR_ADMIN_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: true,
};

export default function getAdminTemplatesReducer(state = initialState, action) {
  // console.log("actiondatata",JSON.stringify(action?.data?.data?.approved_requests));
  
  switch (action.type) {
    case GET_TEMPLATES_FOR_ADMIN: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case GET_TEMPLATES_FOR_ADMIN_SUCCESS: {
      let userRes = Object.assign({}, state);
      // console.log(
      //   "getCarrierSubmittedBotsReducer => Case => Success: while getting submitted bots list: " +
      //     JSON.stringify(action.data)
      // );
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.statusCode;
      userRes.isLoading = false;
      //   userRes.statusCode = action.data.status;
      userRes.submitedTemplates = action?.data?.data?.submmited_templates;
      userRes.approvedTemplates = action?.data?.data?.approved_templates;
      userRes.rejectedTemplates = action?.data?.data?.rejected_templates;
      userRes.forwarded_templates = action?.data?.data?.forwarded_templates;
      return userRes;
    }
    case GET_TEMPLATES_FOR_ADMIN_FAILED: {
      console.error(
        "getCarrierSubmittedBotsReducer => Case => Error: while getting submitted bots list: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);
    }

    case GET_TEMPLATES_FOR_ADMIN_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}