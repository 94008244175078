import { LOADING } from "../../constants/constant";
import {
    DOWNLOAD_DETAILED_EXCEL_REPORT_REQUESTED,
    DOWNLOAD_DETAILED_EXCEL_REPORT_SUCCESS,
    DOWNLOAD_DETAILED_EXCEL_REPORT_FAILED,
    DOWNLOAD_DETAILED_EXCEL_REPORT_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getDetailedExcelReportReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DOWNLOAD_DETAILED_EXCEL_REPORT_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DOWNLOAD_DETAILED_EXCEL_REPORT_SUCCESS:
      let userRes = Object.assign({}, state);
      if(action?.data?.status === 204){
        userRes.status="no_content"
      }
      else if(action?.data?.status === 200){
        userRes.status="success"
      }
      userRes.isLoading = false;
      return userRes;

    case DOWNLOAD_DETAILED_EXCEL_REPORT_FAILED:
      return Object.assign({}, state, action.error);

    case DOWNLOAD_DETAILED_EXCEL_REPORT_RESET:
      let resetObj = Object.assign({}, state);
      resetObj.status = "constant";
      resetObj.statusCode = 0;
      return resetObj;

    default:
      return state;
  }
}
