import { useContext, useEffect, lazy, Suspense } from "react";
import { Route, useRouteMatch, withRouter } from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { AuthContext } from "../Providers/ContextProviders/AuthContext";
import { JSO_CLIENT_CONFIG, redirectToPage } from "../constants/constant";
import { JSO } from "../utils/jso/src/JSO";
import ComingSoon from "../components/Pages/ComingSoon";
import PublicRoutes from "./Routes/PublicRoutes";
import Fallback from "../components/Layout/Fallback";
import FAQ from "../containers/DotGoDevelopers/Public/FAQ/FAQ.container";
import RoleProtectedRoute from "./Routes/RoleProtectedRoute";

const AdminDocumentationPage = lazy(() =>
  import("../components/Pages/DocumentaionPages/AdminDocumentationPage")
);
const AggregatorDocumentationPage = lazy(() =>
  import("../components/Pages/DocumentaionPages/AggregatorDocumentation")
);
// import DotgoMarketing from "./Routes/DotgoMarketing"
// import DotGoAdmin from "./Routes/DotgoAdmin";
// import DotgoDeveloper from "./Routes/DotgoDeveloper";
// import DotGoBilling from "./Routes/DotGoBilling";

const DotgoDeveloper = lazy(() => import("./Routes/DotgoDeveloper"));
const DotGoBilling = lazy(() => import("./Routes/DotGoBilling"));
// const DotGoAdmin = lazy(() => import("./Routes/DotgoAdmin"));
const DotgoMarketing = lazy(() => import("./Routes/DotgoMarketing"));
/**
 * A root of all routes. Entry point for the app.
 *
 * Base path is "/"
 */
const Main = (props) => {
  const { path } = useRouteMatch(); // here path="/"
  console.log("what is the path", path)
  let { setIsLoggedIn } = useContext(AuthContext);
  let client = new JSO(JSO_CLIENT_CONFIG);
  const callbackInfo = client.callback();
  let token = client.checkToken({ name: "user_info" });

  useEffect(() => {
    if (token !== null && typeof token !== 'undefined') {
      setIsLoggedIn(true);
    }
  }, [token]);

  if (callbackInfo?.access_token) {
    redirectToPage(props.history);
    return null;
  } else {
    return (
      <>
        <Route path={path} component={PublicRoutes} />
        <Suspense fallback={<Fallback />}>
          <ProtectedRoute
            path={[`${path}analytics/`]}
            key="developer-dashboard"
            component={DotgoDeveloper}
          />
        </Suspense>
        <Suspense fallback={<Fallback />}>
          <RoleProtectedRoute
            secureFor="admin"
            key="billing-root"
            path={`${path}billing/`}
            component={DotGoBilling}
          />
        </Suspense>
        {/* <Suspense fallback={<Fallback />}>
          <RoleProtectedRoute
            secureFor="admin"
            key="dotgo-admin-root"
            path={`${path}analytics/dotgo-admin/`}
            component={DotGoAdmin}
          />
        </Suspense> */}
        <Suspense fallback={<Fallback />}>
          <ProtectedRoute
            key="marketing"
            path={`${path}marketing/`}
            component={DotgoMarketing}
          />
          <Route
            path="/Carrier/Admin/latestdocumentation"
            exact
            component={AdminDocumentationPage}
          />
          <Route
            path="/Aggregator/documents"
            exact
            component={AggregatorDocumentationPage}
          />
        </Suspense>
        <Route path="/under-development" exact component={ComingSoon} />
        <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} />
        <Route path="/faq" exact component={FAQ} />
        {/* <Route path="/Carrier/Admin/latestdocumentation" exact component={AdminDocumentationPage} />
        <Route path="/Aggregator/documents" exact component={AggregatorDocumentationPage} /> */}
      </>
    );
  }
};
export default withRouter(Main);
