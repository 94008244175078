import { LOADING } from "../../constants/constant";
import {
  GET_DOWNLOAD_UPLOADED_CONTACTS_REQUESTED,
  GET_DOWNLOAD_UPLOADED_CONTACTS_SUCCESS,
  GET_DOWNLOAD_UPLOADED_CONTACTS_FAILED,
  GET_DOWNLOAD_UPLOADED_CONTACTS_RESET_STATE,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getDownloadUploadedContactsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOWNLOAD_UPLOADED_CONTACTS_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_DOWNLOAD_UPLOADED_CONTACTS_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.status = action.data.status_message;
      userRes.statusCode = action.data.status_code;
      userRes.data = action.data.data;
      userRes.isLoading = false;

      console.log("getDownloadUploadedContactsReducer > onSuccess > response > ", JSON.stringify(action))

      return userRes;

    case GET_DOWNLOAD_UPLOADED_CONTACTS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_DOWNLOAD_UPLOADED_CONTACTS_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
