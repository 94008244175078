// import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { requestLogin } from "../../constants/constant";
// import SecureService from "../../service/SecureService";
// import Service from "../../service/Service";
// import { createActionOf } from "../actions/ActionCreator";

// /**
//  *
//  * A hook for handling async actions with redux state.
//  *
//  * Uses existing Service and SecureService classes.
//  */
// export const useFetchAsync = () => {
//   const [isFetching, setIsFetching] = useState(false);
//   const [responseData, setResponseData] = useState(undefined);
//   const [responseStatus, setResponseStatus] = useState(undefined);
//   const [responseError, setResponseError] = useState(undefined);
//   const [rawData, setRawData] = useState(undefined);
//   const dispatch = useDispatch();
//   /**
//    * Calls API.
//    * @param {object} config http request config
//    * @param {object} payload of API
//    * @param  {...any}
//    */
//   const startFetch = async (config = {}, payload = {}, ...args) => {
//     setIsFetching(true);
//     try {
//       if (config?.reduxActions?.hasOwnProperty("start")) {
//         dispatch(createActionOf(config.reduxActions.start, 0));
//       }
//       let response = undefined;
//       const { isSecuredAPI } = handleURL(config.url);
//       const requestMethod = config.method.toLowerCase();

//       // methodObj has methods which will return promise
//       const methodObj = {
//         post: () =>
//           isSecuredAPI
//             ? SecureService.postCall(config.url, payload)
//             : Service.postCall(config.url, payload),
//         put: () =>
//           isSecuredAPI
//             ? SecureService.putCall(config.url, payload)
//             : Service.putCall(config.url, payload),
//         get: () =>
//           isSecuredAPI
//             ? SecureService.getCall(config.url)
//             : Service.getCall(config.url),
//         getnew: () =>
//           isSecuredAPI
//             ? SecureService.getCallNew(config.url)
//             : Service.getCall(config.url),
//         postnew: () =>
//           isSecuredAPI
//             ? SecureService.postCallNew(config.url, payload)
//             : Service.getCall(config.url),
//       };

//       const postRequest = methodObj[requestMethod];
//       response = await handleRequest(postRequest);

//       if (typeof config.callback === "function") {
//         config.callback(response, undefined);
//       }
//       const { data = {} } = response || {};
//       console.log("response in useFetchAsync :", response);
//       setRawData(response);
//       setResponseStatus(response);
//       setResponseData(data);
//       setIsFetching(false);
//       if (config?.reduxActions?.hasOwnProperty("success")) {
//         dispatch(
//           createActionOf(config.reduxActions.success, 1, {
//             data: data,
//             resData: response,
//           })
//         );
//       }
//     } catch (error) {
//       if (error?.response?.status === 401) {
//         requestLogin();
//         return;
//       }
//       if (typeof config.callback === "function") {
//         config.callback(undefined, error);
//       }
//       setIsFetching(false);
//       setResponseError(error);
//       console.error(
//         "useFetchAsync Error while processing the request: ",
//         error
//       );
//       if (config?.reduxActions?.hasOwnProperty("failed")) {
//         dispatch(createActionOf(config.reduxActions.failed, 2, error));
//       }
//     }
//   };

//   const resetState = () => {
//     setIsFetching(undefined);
//     setResponseData(undefined);
//     setResponseStatus(undefined);
//     setResponseError(undefined);
//     setRawData(undefined);
//   };

//   const handleURL = (url = "") => ({
//     url,
//     isSecuredAPI: url.includes("/secure/"),
//   });

//   const handleRequest = async (request) => {
//     try {
//       if (typeof request !== "function") {
//         throw Error("request is not a function");
//       }
//       return await request();
//     } catch (error) {
//       // if (error?.response?.status == 401) {
//       //   // TODO: await refresh token
//       //   // await refreshToken()
//       //   // return await request()
//       // }
//       throw error;
//     }
//   };

//   const dispatchAction = (action = "", type = 0, payload = {}) => {
//     return () => {
//       dispatch(createActionOf(action, type, payload));
//     };
//   };

//   return {
//     startFetch,
//     isFetching,
//     dispatchAction,
//     responseData,
//     responseStatus,
//     responseError,
//     rawData,
//     resetState,
//   };
// };
import { useState } from "react";
import { useDispatch } from "react-redux";
import { requestLogin } from "../../constants/constant";
import SecureService from "../../service/SecureService";
import Service from "../../service/Service";
import { createActionOf } from "../actions/ActionCreator";

/**
 *
 * A hook for handling async actions with redux state.
 *
 * Uses existing Service and SecureService classes.
 */
export const useFetchAsync = (hookCfg = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [responseData, setResponseData] = useState(undefined);
  const [responseStatus, setResponseStatus] = useState(undefined);
  const [responseError, setResponseError] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const dispatch = useDispatch();
  /**
   * Calls API.
   * @param {object} config http request config
   * @param {object} payload of API
   * @param  {...any}
   */
  const startFetch = async (config = {}, payload = {}, ...args) => {
    setIsFetching(true);
    try {
      if (config?.reduxActions?.hasOwnProperty("start")) {
        dispatch(createActionOf(config.reduxActions.start, 0));
      }
      let response = undefined;
      const { isSecuredAPI } = handleURL(config.url);
      const requestMethod = config.method.toLowerCase();

      // methodObj has methods which will return promise
      const methodObj = {
        post: () =>
          isSecuredAPI
            ? SecureService.postCall(config.url, payload)
            : Service.postCall(config.url, payload),
        put: () =>
          isSecuredAPI
            ? SecureService.putCall(config.url, payload)
            : Service.putCall(config.url, payload),
        get: () =>
          isSecuredAPI
            ? SecureService.getCall(config.url)
            : Service.getCall(config.url),
        getnew: () =>
          isSecuredAPI
            ? SecureService.getCallNew(config.url)
            : Service.getCall(config.url),
        postnew: () =>
          isSecuredAPI
            ? SecureService.postCallNew(config.url, payload)
            : Service.getCall(config.url),
      };

      const postRequest = methodObj[requestMethod];
      if (hookCfg.isTest === true) {
        response = await Promise.resolve(config.fakeResponse);
      } else {
        response = await handleRequest(postRequest);
      }

      if (typeof config.callback === "function") {
        config.callback(response, undefined);
      }
      const { data = {} } = response || {};
      console.log("response in useFetchAsync :", response);
      setRawData(response);
      setResponseStatus(response);
      setResponseData(data);
      setIsFetching(false);
      if (config?.reduxActions?.hasOwnProperty("success")) {
        dispatch(
          createActionOf(config.reduxActions.success, 1, {
            data: data,
            resData: response,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        console.error("[API CALL] Error while connecting server:[401]", error);
        requestLogin();
        return;
      }
      if (typeof config.callback === "function") {
        config.callback(undefined, error);
      }
      setIsFetching(false);
      setResponseError(error);
      console.error(
        "useFetchAsync Error while processing the request: ",
        error
      );
      if (config?.reduxActions?.hasOwnProperty("failed")) {
        dispatch(createActionOf(config.reduxActions.failed, 2, error));
      }
    }
  };

  const resetState = () => {
    setIsFetching(undefined);
    setResponseData(undefined);
    setResponseStatus(undefined);
    setResponseError(undefined);
    setRawData(undefined);
  };

  const handleURL = (url = "") => ({
    url,
    isSecuredAPI: url.includes("/secure/"),
  });

  const handleRequest = async (request) => {
    console.log("request func ", request);
    try {
      if (typeof request !== "function") {
        throw Error("request is not a function");
      }
      return await request();
    } catch (error) {
      // if (error?.response?.status == 401) {
      //   // TODO: await refresh token
      //   // await refreshToken()
      //   // return await request()
      // }
      throw error;
    }
  };

  const dispatchAction = (action = "", type = 0, payload = {}) => {
    return () => {
      dispatch(createActionOf(action, type, payload));
    };
  };

  return {
    startFetch,
    isFetching,
    dispatchAction,
    responseData,
    responseStatus,
    responseError,
    rawData,
    resetState,
  };
};