import { LOADING } from "../../../../constants/constant";
import {
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET,
  ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET,
  ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS,
} from "../../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: null,
};

export function adminSubmittedForVerification(state = initialState, action) {
  switch (action.type) {
    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_SUCCESS: {
      let userRes = Object.assign({}, state);
      userRes.status = action.data.statusMessage;
      userRes.statusCode = action.data.statusCode;
      userRes.isLoading = false;
      userRes.approvedBotList = action.data.data?.approved_bots ?? [];
      userRes.rejectedBotList = action.data.data?.rejected_bots ?? [];
      userRes.submittedBots = action.data.data?.submmited_bots ?? [];
      userRes.carrierPendingBots = action.data.data?.pending_bots ?? [];

      return userRes;
    }
    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_FAILED: {
      return Object.assign({}, state, action.error);
    }

    case ADMIN_SUBMITTED_BOTS_FOR_VERIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}

export function adminApproveRejectForVerification(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION: {
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_SUCCESS: {
      let userRes = Object.assign({}, state);
      userRes.status = action.data.statusMessage;

      console.log("status code::: -> ", action.data);
      userRes.statusCode = action.data.status_code;
      userRes.isLoading = false;
      return userRes;
    }
    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_FAILED: {
      return Object.assign({}, state, action.error);
    }

    case ADMIN_APPROVE_REJECT_BOT_FOR_VERIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
