import { LOADING, STATUS_CODE_SUCCESS } from "../../constants/constant";
import {
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_RESET,
} from "../actions/ActionType";

const initialState = {
  status: "",
  isLoading: false,
};

export default function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case CHANGE_PASSWORD_SUCCESS:
      let userRes = Object.assign({}, state);
      console.log(
        "changePasswordReducer => Case => Success: while changing password: " +
          JSON.stringify(action.data)
      );
      userRes.status = action.data.status_message;
      userRes.isLoading = false;
      userRes.statusCode = action.data.status_code;
      if (userRes.statusCode !== STATUS_CODE_SUCCESS) {
        userRes.error = true;
        userRes.errorMessage = action.data.message;
        return userRes;
      }
      userRes.message = action.data.data;
      userRes.error = false;
      return userRes;

    case CHANGE_PASSWORD_FAILED:
      console.log(
        "changePasswordReducer => Case => Error: while changing password: " +
          JSON.stringify(action.error)
      );
      return Object.assign({}, state, action.error);

    case CHANGE_PASSWORD_RESET:
      return initialState;

    default:
      return state;
  }
}
