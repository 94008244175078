import { LOADING } from "../../../constants/constant";
import {
  GET_BILLING_SUMMARY_ANALYTICS,
  GET_BILLING_SUMMARY_ANALYTICS_SUCCESS,
  GET_BILLING_SUMMARY_ANALYTICS_FAILED,
  GET_BILLING_SUMMARY_ANALYTICS_RESET,
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function analyticsBillingSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BILLING_SUMMARY_ANALYTICS:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BILLING_SUMMARY_ANALYTICS_SUCCESS:
      let filteredData = Object.assign({}, state);
      filteredData.status = action.data.message;
      filteredData.isLoading = false;
      filteredData.statusCode = action.data.status_code;
      // filteredData.cardSummary = action.data.data.finalMap
      //   ? action.data.data.finalMap
      //   : [];
      // filteredData.tableData = action.data.data.summaryList
      //   ? action.data.data.summaryList
      //   : [];
      filteredData.cardSummary = action.data.data ? action.data.data : []
      return filteredData;

    case GET_BILLING_SUMMARY_ANALYTICS_FAILED:
      return Object.assign({}, state, action.error);

    case GET_BILLING_SUMMARY_ANALYTICS_RESET:
      return initialState;

    default:
      return state;
  }
}
