import { LOADING, STATUS_CODE } from "../../../constants/constant";
import {
    GET_TEMPLATE_HISTORY,
    GET_TEMPLATE_HISTORY_SUCCESS,
    GET_TEMPLATE_HISTORY_FAILED,
    GET_TEMPLATE_HISTORY_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function getTemplateHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATE_HISTORY:
      let reqstState = Object.assign({}, state)
      reqstState.isLoading = true
      reqstState.status = LOADING
      reqstState.statusCode = STATUS_CODE
      return reqstState

    case GET_TEMPLATE_HISTORY_SUCCESS:
      let userRes = Object.assign({}, state)
      console.log(
        "getTemplateDetailsReducer Success: " +
          JSON.stringify(action.data)
      )
      //userRes.status = action.data.status;
      userRes.isLoading = false
      userRes.status = action.data.data.status_message
      userRes.statusCode = action.data.data.status_code

      userRes.list = action.data.data.data
      return userRes

    case GET_TEMPLATE_HISTORY_FAILED:
      console.error(
        "getTemplateDetailsReducer => Error: while getting template details: " +
          JSON.stringify(action.data.error)
      )
      return Object.assign({}, state, action.data.error)

    case GET_TEMPLATE_HISTORY_RESET:
      return initialState

    default:
      return state
  }
}
