import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { config, requestLogout } from "../../constants/constant";
import { AuthContext } from "../../Providers/ContextProviders/AuthContext";

const Footer = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <React.Fragment>
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 text-center"
              style={{ fontSize: "13px", paddingBottom: "13px" }}
            >
              <p>
                Copyright ©  VI 2023-2024. All rights reserved &nbsp; &nbsp;
                | &nbsp; &nbsp;{" "}
                <a href={`${config.ANALYTICS_TO_DEVELOPER_REDIRECT_URI}/developer-privacypolicy`} className="" target="_blank">
                  Privacy Policy
                </a>{" "}
                &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                {/* <NavLink className="" target="_blank" to="/developer-tos">
                  Terms of Service
                </NavLink> */}
                <a
                  href={`${config.ANALYTICS_TO_DEVELOPER_REDIRECT_URI}/developer-tos`}
                  className=""
                  target="_blank"
                >
                  Terms of Service
                </a>
                &nbsp; &nbsp; | &nbsp; &nbsp;{" "}
                {/* <NavLink className="" target="_blank" to="/rbm-terms">
                  RBM Terms
                </NavLink> */}
                {/* <a
                  href={`${config.ANALYTICS_TO_DEVELOPER_REDIRECT_URI}/rbm-terms`}
                  className=""
                  target="_blank"
                >
                  RBM Terms
                </a>
                &nbsp; &nbsp; | &nbsp; &nbsp;{" "} */}
                {/* <NavLink className="" target="_blank" to="/rbm-policies">
                  RBM Policies
                </NavLink> */}
                <a
                  href={`${config.ANALYTICS_TO_DEVELOPER_REDIRECT_URI}/rbm-policies`}
                  className=""
                  target="_blank"
                >
                  RBM Policies
                </a>
                {isLoggedIn ? (
                  <>
                    &nbsp; &nbsp; | &nbsp; &nbsp;
                    <a href={`${config.ANALYTICS_TO_DEVELOPER_REDIRECT_URI}/escalation-matrix`}>
                      Escalation Matrix
                    </a>
                  </>
                ) : (
                  <></>
                )}
                <span style={{float: "right"}}>
                  Powered By &nbsp;
                  <a href="https://www.dotgo.com" style={{textDecoration : "underline"}} target="_blank" rel="noreferrer">
                    Dotgo
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Footer;
