import { LOADING } from "../../constants/constant";
import {
    GET_BILLING_DETAILED_REPORT_REQUESTED,
    GET_BILLING_DETAILED_REPORT_SUCCESS,
    GET_BILLING_DETAILED_REPORT_FAILED,
    RESET_BILLING_DETAILED_REPORT,
} from "../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: false,
};

export default function getBillingDetailedReportReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_BILLING_DETAILED_REPORT_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case GET_BILLING_DETAILED_REPORT_SUCCESS:
      let userRes = Object.assign({}, state);
      userRes.isLoading = false;
      return userRes;

    case GET_BILLING_DETAILED_REPORT_FAILED:
      return Object.assign({}, state, action.error);

    case RESET_BILLING_DETAILED_REPORT:
      let resetObj = Object.assign({}, state);
      resetObj.status = "constant";
      resetObj.statusCode = 0;
      return resetObj;

    default:
      return state;
  }
}
