import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_TRAFFIC_TABLE_DATA,
  GET_TRAFFIC_TABLE_DATA_SUCCESS,
  GET_TRAFFIC_TABLE_DATA_FAILED,
} from "../actions/ActionType";
import { API_ENDPOINT } from "../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../appApi/AppApi";
import { createActionOf } from "../actions/ActionCreator";

function* workerGetTrafficTableData(payLoad) {
  let response = {};
  try {    
    response = yield call(
      makeSecuredPostRequest,
      API_ENDPOINT.GET_TRAFFIC_TABLE_DATA, 
      payLoad.data
    );    
    yield put(createActionOf(GET_TRAFFIC_TABLE_DATA_SUCCESS, 1, response));
  } catch (error) {
    yield put(createActionOf(GET_TRAFFIC_TABLE_DATA_FAILED, 2, error));
  }
}

export const watchGetTrafficTableData = takeLatest(
  GET_TRAFFIC_TABLE_DATA,
  workerGetTrafficTableData
);
