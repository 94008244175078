import React, { useState, useEffect, useMemo, useContext } from "react";
import { useFetchAsync } from "../../../../store/hooks/useFetchAsync";
import { Loader, Popup } from "semantic-ui-react";
import { API_ENDPOINT } from "../../../../store/URLs/ApiEndpoints";
import { onBackByOne } from "../../../../utils/otherUtils";
import { isTokenValid } from "../../../../utils/otherUtils";
import { AuthContext } from "../../../../Providers/ContextProviders/AuthContext";

// import F2 from "./F2";
const FAQ = () => {
  const faqApi = useFetchAsync();
  const [search, setSearch] = useState("");
  const [faqSelected, setFaqSelected] = useState(0);
  const [faq, setfaq] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);

  //Initial load
  useEffect(() => {
    faqApi.startFetch({
      method: "getNew",
      url: isLoggedIn ? API_ENDPOINT.GET_FAQ_SECURE :API_ENDPOINT.GET_FAQ,
    });
    // setfaq(F2()["data"]);
  }, [isLoggedIn]);

  //On query change
  const searchResult = useMemo(() => {
    if (search.length < 2 || search.trim().length < 1) return [];
    let list = [];
    let searchQuery = search.trim().toLowerCase();
    faq.forEach((item) => {
      item.faq_list.forEach((faqItem) => {
        if (
          faqItem.query.toLowerCase().includes(searchQuery) ||
          faqItem.answer.toLowerCase().includes(searchQuery)
        ) {
          list.push(faqItem);
        }
      });
    });
    // return list;
    let clean = list.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.query === arr.query && t.answer === arr.answer)))

    return clean;
  }, [search]);

  useEffect(() => {
    if (search.length !== 0) setFaqSelected(-1);
  }, [search]);

  //On Faq data change
  useEffect(() => {
    if (!faqApi.responseData) return;
    setfaq(faqApi.responseData?.data? faqApi.responseData?.data :faqApi.responseData);  }, [faqApi.responseData]);
  if (!faqApi.responseData || faqApi.isFetching || faqApi.responseError) {
    return (
      <div className="vh-min">
        <Loader size="large" active content={"Loading FAQ's"} />
      </div>
    );
  }

  return (
    <section style={{ marginTop: "85px" }}>
      <div className="container">
        <div className="row">
          <div>
            {/* <div className={"faq-tab-fixed faq-tab-h1"}>
              <ul className="nav nav-pills brand-pills nav-stacked rg-tab">
                <li className="tab-search">
                  <div className="form-group has-feedback has-search">
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </li>
                {faq.map((item, idx) => {
                  return (
                    <li
                      className={`brand-nav ${
                        // idx === (search.length > 1 ? 0 : faqSelected)
                        idx === 0 && faqSelected !== -1 ? "active" : ""
                      }`}
                      key={`${idx}${search}`}
                      onClick={() => {
                        setSearch("");
                        if (search.length > 0) setFaqSelected(-1);
                      }}
                    >
                      <a
                        href={`#${item.cat_type.trim().replaceAll(" ", "")}`}
                        data-toggle="tab"
                      >
                        {item.cat_type}
                        <span className="glyphicon glyphicon-chevron-right pull-right" />
                      </a>
                    </li>
                  );
                })}
                <li
                  className={`brand-nav filler-faq`}
                  style={{ minHeight: "25vh", background: "#fff9e6" }}
                ></li>
              </ul>
            </div> */}
            <div className="col-lg-12 col-md-12">
              <div className="col-lg-12 col-md-12">
                <h2 className="font-size-26" style={{ color: "#000000" }}>
                  {Array.isArray(faq) ? faq[0]?.cat_type : ""}
                </h2>
              </div>
              <div className="col-xs-8 col-sm-8 col-lg-4 col-md-5 margin-top-10">
                <div className="form-group has-feedback has-search">
                  <label htmlFor="search-box">
                    Enter Keyword to search FAQs
                  </label>
                  <input
                    id="search-box"
                    type="text"
                    className="form-control"
                    placeholder="Keyword Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-12">
              <div className="tab-content">
                {search.length < 1 ? (
                  faq?.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`tab-pane ${idx === 0 ? "active" : ""}`}
                        id={`${item.cat_type.trim().replaceAll(" ", "")}`}
                      >
                        <div className="col-lg-12 col-md-12">
                          <div className="row" style={{ minHeight: "67vh" }}>
                            <div className="col-lg-12 col-md-12 table-view-add-template margin-bottom-25 margin-top-25 faqs">
                              <div
                                className="panel-group"
                                // id={`accordion${idx}`}
                                role="tablist"
                                aria-multiselectable="true"
                              >
                                {item.faq_list.length === 0 ? (
                                  <div className="panel panel-default">
                                    <div
                                      className="panel-heading"
                                      role="tab"
                                      id="heading1000"
                                    >
                                      <h4 className="panel-title">
                                        <a
                                          role="button"
                                          data-parent="#accordion100"
                                        >
                                          No FAQ available for this category
                                        </a>
                                      </h4>
                                    </div>
                                    <div
                                      id="collapse1000"
                                      className="panel-collapse collapse in"
                                      role="tabpanel"
                                      aria-labelledby="heading1000"
                                    ></div>
                                  </div>
                                ) : (
                                  item.faq_list?.map((faqItem, faqIdx) => {
                                    return (
                                      <PanelQa
                                        key={faqIdx}
                                        parentId={idx}
                                        headingId={faqIdx}
                                        question={faqItem.query}
                                        answer={faqItem.answer}
                                        activateAll={true}
                                      />
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {/* <h2 className="font-size-24">Search</h2> */}
                        <h2
                          style={{ color: "#000000" }}
                          className="font-size-26"
                        >
                          Search
                        </h2>
                      </div>
                    </div>
                    <div className="row" style={{ minHeight: "67vh" }}>
                      <div className="col-lg-12 col-md-12 table-view-add-template margin-bottom-25 margin-top-25 faqs">
                        <div
                          className="panel-group"
                          // id={`accordion100`}
                          role="tablist"
                          aria-multiselectable="true"
                        >
                          {searchResult.length === 0 ? (
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading1000"
                              >
                                <h4 className="panel-title">
                                  <a role="button" data-parent="#accordion100">
                                    {search.length < 2
                                      ? "Please enter a minimum of 2 characters to search"
                                      : "No search results found."}
                                  </a>
                                </h4>
                              </div>
                              <div
                                id="collapse1000"
                                className="panel-collapse collapse in"
                                role="tabpanel"
                                aria-labelledby="heading1000"
                              ></div>
                            </div>
                          ) : (
                            <>
                              {searchResult?.map((faqItem, faqIdx) => {
                                return (
                                  <PanelQa
                                    key={faqIdx}
                                    parentId={"100"}
                                    headingId={faqIdx}
                                    question={faqItem.query}
                                    answer={faqItem.answer}
                                  />
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row text-center">
                <div className="col-lg-12 col-md-12 col-sm-12 margin-bottom-35 margin-top-35">
                  <button
                    onClick={onBackByOne}
                    type="button"
                    className="btn btn-gray-round-bdr"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function PanelQa({
  parentId = 1,
  headingId = 1,
  question = "",
  answer = "",
  activateAll = false,
}) {
  if (headingId === 0) {
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading"
          role="tab"
          id={`heading${parentId}${headingId}`}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent={"#accordion" + parentId}
              href={`#collapse${parentId}${headingId}`}
              aria-expanded="true"
              aria-controls={`collapse${parentId}${headingId}`}
              dangerouslySetInnerHTML={{ __html: question }}
            >
              {/* {question} */}
            </a>
          </h4>
        </div>
        <div
          id={`collapse${parentId}${headingId}`}
          className="panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby={`heading${parentId}${headingId}`}
        >
          <div
            className="panel-body"
            dangerouslySetInnerHTML={{ __html: answer }}
          ></div>
        </div>
      </div>
    );
  }
  if (activateAll) {
    <div className="panel panel-default margin-top-25">
      <div
        className="panel-heading"
        role="tab"
        id={`heading${parentId}${headingId}`}
      >
        <h4 className="panel-title">
          <a
            role="button"
            data-toggle="collapse"
            data-parent={"#accordion" + parentId}
            href={`#collapse${parentId}${headingId}`}
            aria-expanded="true"
            aria-controls={`collapse${parentId}${headingId}`}
            dangerouslySetInnerHTML={{ __html: question }}
          ></a>
        </h4>
      </div>
      <div
        id={`collapse${parentId}${headingId}`}
        className="panel-collapse collapse in"
        role="tabpanel"
        aria-labelledby={`heading${parentId}${headingId}`}
      >
        <div
          className="panel-body"
          dangerouslySetInnerHTML={{ __html: answer }}
        ></div>
      </div>
    </div>;
  }
  return (
    <>
      <div className="panel panel-default margin-top-25">
        <div
          className="panel-heading"
          role="tab"
          id={`heading${parentId}${headingId}`}
        >
          <h4 className="panel-title">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent={"#accordion" + parentId}
              href={`#collapse${parentId}${headingId}`}
              aria-expanded="false"
              aria-controls={`collapse${parentId}${headingId}`}
              dangerouslySetInnerHTML={{ __html: question }}
            >
              {/* {question} */}
            </a>
          </h4>
        </div>
        <div
          id={`collapse${parentId}${headingId}`}
          className="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby={`heading${parentId}${headingId}`}
        >
          <div
            className="panel-body"
            dangerouslySetInnerHTML={{ __html: answer }}
          ></div>
        </div>
      </div>
    </>
  );
}
export default FAQ;
