import { LOADING } from "../../../constants/constant";
import {
  DELETE_TEST_DEVICES_REQUESTED,
  DELETE_TEST_DEVICES_SUCCESS,
  DELETE_TEST_DEVICES_FAILED,
  DELETE_TEST_DEVICES_RESET
} from "../../actions/ActionType";

const initialState = {
  status: "INIT",
  isLoading: undefined,
};

export default function deleteTestDevicesReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_TEST_DEVICES_REQUESTED:
      let reqstState = Object.assign({}, state);
      reqstState.isLoading = true;
      reqstState.status = LOADING;
      return reqstState;

    case DELETE_TEST_DEVICES_SUCCESS:
     
      let userRes = Object.assign({}, state);
      userRes.status_message = action.data.data.status_message;
      userRes.statusCode = action.data.data.status_code;
      userRes.isLoading = false;
      
      return userRes;

    case DELETE_TEST_DEVICES_FAILED:
      console.error("Error while deleting test devices", action.error);
      return Object.assign({}, state, action.error);

      case DELETE_TEST_DEVICES_RESET: {
        return initialState;
      }
    default:
      return state;
  }
}
