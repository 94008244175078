import { config } from "../../constants/constant";

const aggregatorBilling = {
  // GET_AGGREGATOR_BOT_BRAND_CARRIER_LISTS:
  //   config.BASE_URI + "/secure/api/v3/get_bot_brand_carrier_for_aggregator",
  GET_AGGREGATOR_BOT_BRAND_CARRIER_LISTS:
  config.BASE_ANALYTICS_URI + "/secure/getFilterDataForBilling",
  GET_AGGREGATOR_BILLING_INFO:
  config.BASE_ANALYTICS_URI + "/secure/listByTabularView",
  // GET_AGGREGATOR_BILLING_INFO:
  //   config.BASE_URI + "/secure/api/v3/get_aggregator_billing",
  GET_AGGREGATOR_BILLING_INFO_SUMMARY:
  config.BASE_ANALYTICS_URI + "/secure/getBillingSummary",
  DOWNLOAD_AGGREGATOR_BILLING_REPORT_PDF:
    config.BASE_URI +
    "/secure/api/v3/download_pdf_report_for_aggregator_billing_dashboard",
  DOWNLOAD_AGGREGATOR_BILLING_REPORT_XLS:
    config.BASE_URI +
    "/secure/api/v3/download_excel_report_for_aggregator_billing_dashboard",
};
export default aggregatorBilling;
// AGGREGATOR_BILLING_INFO_SUMMARY
// AGGREGATOR_BILLING_INFO_SUMMARY_SUCCESS
// AGGREGATOR_BILLING_INFO_SUMMARY_FAILED
// AGGREGATOR_BILLING_INFO_SUMMARY_RESET