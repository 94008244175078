import { put, takeLatest, call } from "redux-saga/effects";
import {
    GET_BILLING_TABULAR_VIEW_ANALYTICS,
    GET_BILLING_TABULAR_VIEW_ANALYTICS_SUCCESS,
    GET_BILLING_TABULAR_VIEW_ANALYTICS_FAILED,
} from "../../actions/ActionType";
import { API_ENDPOINT } from "../../URLs/ApiEndpoints";
import { makeSecuredPostRequest } from "../../../appApi/AppApi";
import { createActionOf } from "../../actions/ActionCreator";

function* workerAnalyticsBillingTabularView(payLoad) {
  let response = {};
  console.log("getting called")
  try {
    // if (payLoad.data.whoIsThis === "super-admin") {
    //   response = yield call(
    //     makeSecuredPostRequest,
    //     API_ENDPOINT.DOTGO_ANALYTICS_API,
    //     payLoad.data
    //   );
    // } 
    // else if (payLoad.data.whoIsThis === "vi-admin") {
    //   response = yield call(
    //     makeSecuredPostRequest,
    //     API_ENDPOINT.GET_DASHBOARD_CARRIER_ANALYTICS_API,
    //     payLoad.data
    //   );
    // } 
    
      response = yield call(
        makeSecuredPostRequest,
        API_ENDPOINT.ADMIN_ANALYTICS_TABULAR_VIEW, //We will call this API for both Super Admin & Carrier (VI) Admin (Tabular View)
        payLoad.data
      );

      // response for aggregator | bot | brand | date
    //   response = {
    //     data: {
    //       "status_code": 10,
    //       "status_message": "Success",
    //       "message": null,
    //       "data": {
    //           "data": [
    //               {
    //                   "domesticMessageMap": {
    //                       "p2a_conversation": 0,
    //                       "rich_otp": 0,
    //                       "revenue": 11.0,
    //                       "basic_message": 4,
    //                       "a2p_conversation": 1,
    //                       "single_message": 5,
    //                       "currency_code": "LKR"
    //                   },
    //                   "internationalMessageMap": {
    //                       "p2a_conversation": 0,
    //                       "rich_otp": 0,
    //                       "revenue": 0.0,
    //                       "basic_message": 0,
    //                       "a2p_conversation": 0,
    //                       "single_message": 0,
    //                       "currency_code": null
    //                   },
    //                   "date": "2022-11-11",
    //                   "brand_name": "Guy Campbell Analytics",
    //                   "company_name": "Jacobson Duncan Trading Analytics",
    //                   "bot_name": "Jaquelyn Gutierrez Analytics"

    //               }
    //           ],
    //           "type": null
    //       }
    //   }
    //   }

      //response for transaction type
    //   let responseForTransactionType = {
    //     data: {
    //       "status_code": 10,
    //       "status_message": "Success",
    //       "message": null,
    //       "data": [
    //           {
    //               "data": [
    //                   {
    //                       "domesticMessageMap": {
    //                           "p2a_conversation": 0,
    //                           "rich_otp": 0,
    //                           "revenue": 7.0,
    //                           "basic_message": 0,
    //                           "a2p_conversation": 1,
    //                           "single_message": 5,
    //                           "currency_code": null
    //                       },
    //                       "partnersId": "187",
    //                       "company_name": "Vodafone Idea Limited"
    //                   },
    //                   {
    //                       "domesticMessageMap": {
    //                           "p2a_conversation": 0,
    //                           "rich_otp": 0,
    //                           "revenue": 4.0,
    //                           "basic_message": 4,
    //                           "a2p_conversation": 0,
    //                           "single_message": 0,
    //                           "currency_code": null
    //                       },
    //                       "partnersId": "215",
    //                       "company_name": "gupshup"
    //                   }
    //               ],
    //               "type": "domestic"
    //           },
    //           {
    //               "data": [],
    //               "type": "international"
    //           }
    //       ]
    //   }
    //   }
    
    yield put(createActionOf(GET_BILLING_TABULAR_VIEW_ANALYTICS_SUCCESS, 1, response));
  } catch (error) {
    console.log("I am an error")
    console.error("Worker: analytics tabular view failed: " + error.message);
    yield put(createActionOf(GET_BILLING_TABULAR_VIEW_ANALYTICS_FAILED, 2, error));
  }
}

export const watchAnalyticsBillingTabularView = takeLatest(
    GET_BILLING_TABULAR_VIEW_ANALYTICS,
  workerAnalyticsBillingTabularView
);
